import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import SubjectsContext from "./Contexts/SubjectContext";

const Instructors = () => {
  const { subjects } = useContext(SubjectsContext);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortBy, setSortBy] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
  }, []);
  
  const instructorsMap = {};

  // Building the instructorsMap from the subjects data
  subjects?.forEach((subject) => {
    const instructor = subject.instructor;

    if (!instructorsMap[instructor.uid]) {
      instructorsMap[instructor.uid] = {
        ...instructor,
        subjects: [],
        totalStudents: 0,
        totalRatings: 0,
        totalSubjects: 0,
      };
    }

    // Only add the subject to the instructor's subjects array if it is published and reviewed
    if (subject.is_published && subject.is_reviewed) {
      const numStudents = subject.students.length;
      const ratingsSum = subject.reviews.reduce((total, review) => total + review.rating, 0);
      const avgRating = ratingsSum / subject.reviews.length;

      // Update instructor's totals
      instructorsMap[instructor.uid].totalStudents += numStudents;
      instructorsMap[instructor.uid].totalRatings += avgRating;
      instructorsMap[instructor.uid].totalSubjects++;

      instructorsMap[instructor.uid].subjects.push({
        id: subject.id,
        name: subject.name,
        description: subject.description,
        price: subject.price,
        numStudents: numStudents,
        avgRating: avgRating.toFixed(2),
      });
    }
  });

  // Calculate the overall average rating for each instructor
  Object.values(instructorsMap).forEach((instructor) => {
    if (instructor.totalSubjects > 0) {
      instructor.avgRating = (instructor.totalRatings / instructor.totalSubjects).toFixed(1);
    } else {
      instructor.avgRating = 0;
    }
  });

  const instructors = Object.values(instructorsMap);

  // Filter instructors based on the search query
  const filteredInstructors = instructors?.filter(
    (instructor) =>
      (instructor.displayName && instructor.displayName.toLowerCase().includes(searchQuery.toLowerCase())) ||
      instructor.subjects.some((subject) =>
        subject.name?.toLowerCase().includes(searchQuery.toLowerCase())
      )
  );

  // Handle search form submission
  const handleSearchSubmit = (e) => {
    e.preventDefault();
  };

  // Sort instructors based on the selected criterion
  let sortedInstructors = [...filteredInstructors]; // Create a copy to avoid mutating the original array
  if (sortBy === "totalRating") {
    sortedInstructors.sort((a, b) => b.avgRating - a.avgRating);
  } else if (sortBy === "totalStudents") {
    sortedInstructors.sort((a, b) => b.totalStudents - a.totalStudents);
  }

  return (
    <div className="container mt-5">
      <h5 className="mx-2">Teachers</h5>
      <div className="mt-5 mb-2">
        <form className="d-flex mx-2" onSubmit={handleSearchSubmit}>
          <input
            type="text"
            placeholder="Search by name or subject"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="sm-mx-1 form-control me-sm-2 input"
            style={{ maxWidth: "400px" }}
          />
        </form>
      </div>
      <div style={{ maxWidth: "400px" }} className="d-flex mb-5 mx-2">
        <select
          value={sortBy}
          onChange={(e) => setSortBy(e.target.value)}
          className="form-select sm-mx-1 me-sm-2"
          aria-label="Sort by"
        >
          <option value="">Sort by</option>
          <option value="totalRating">Average Rating</option>
          <option value="totalStudents">Total Students</option>
        </select>
      </div>
      <div className="my-4 mx-2">
        <div className="instructors-container">
          {sortedInstructors.map((instructor) => (
            <div className="text-start instructor" key={instructor.uid}>
              <div className="card mb-4">
                <div className="card-body text-center">
                  <img
                    src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava3.webp"
                    alt="avatar"
                    className="rounded-circle img-fluid"
                    style={{ width: "150px" }}
                  />
                  <h5 className="fw-bolder">{instructor.displayName}</h5>
                  <p>Total Students: <span className="text-primary">{instructor.totalStudents}</span></p>
                  <p>Average Rating: <span className="text-primary">{instructor.avgRating}</span></p>
                  <div className="text-center">
                    {instructor.subjects?.length > 0 ? (
                      instructor.subjects.slice(0, 2).map((subject) => (
                        <div key={subject.id} className="text-center">
                          <Link
                            state={{ data: subject }}
                            style={{ textDecoration: "none" }}
                            to={`/display-subjects/${subject.name}`}
                          >
                            <small>{subject.name}</small>
                          </Link>
                        </div>
                      ))
                    ) : (
                      <p>No subjects available</p>
                    )}
                  </div>
                  <div className="mb-2">
                    <button className="btn btn-dark w-75">
                      <Link
                        state={{ data: instructor }}
                        style={{ textDecoration: "none" }}
                        to={`/instructors/${instructor.uid}`}
                      >
                        View Teacher Profile
                      </Link>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Instructors;
