// Create a new component called MessageResponsePopup
import React from "react";

function MessageRepliesPopup({ selectedMessage, onClose }) {
  const responses = selectedMessage.responses || [];

  return (
    <div className="message-response-popup">
      <button onClick={onClose}>Close</button>
      <h3>Responses</h3>
      <ul>
        {responses.map((response) => (
          <li key={response.id}>
            <p>{response.text}</p>
            {/* Display other response details as needed */}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default MessageRepliesPopup;
