import React from "react";
import { useParams } from "react-router-dom";
import CreateLesson from "./CreateLesson";

function UserSubject() {
  const { subject } = useParams();
  const route = `${subject}/lesson/l`;

  return (
    <>
      <div className="mt-5 mb-5">
        <CreateLesson subject={subject} path={route} />
      </div>
    </>
  );
}

export default UserSubject;


