import React, { useContext, useState,useEffect } from "react";
import { auth } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link } from "react-router-dom";
import GroupsContext from "./Contexts/GroupsContext";
import Fuse from "fuse.js";
import { useNavigate } from "react-router-dom";
import StarIcons from "./StarIcons";
import image1 from "./images/subjects4.svg";
import subject_img from "./subject.svg";

function DisplayMaterials() {
  const values = useContext(GroupsContext);
  
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
  }, []);

  const [gradeFilter, setGradeFilter] = useState("All");
  const [subjectFilter, setSubjectFilter] = useState("All");
  const [searchInput, setSearchInput] = useState("");

  const { uid, displayName, photoURL, bio, education, modules } =
    auth.currentUser;

  function confirmDeletion(event, materialId) {
    // Display a confirmation dialog
    if (window.confirm("Are you sure you want to delete this material?")) {
      // If the user confirms, call the deleteMaterials function
      values.deleteMaterials(event, materialId);
    } else {
      // If the user cancels, do nothing
    }
  }

  function filterMaterials(material) {
    const gradeMatch =
      gradeFilter === "All" ||
      material.file_name2.toLowerCase().includes(gradeFilter.toLowerCase()) ||
      material.file_name1.toLowerCase().includes(gradeFilter.toLowerCase()) ||
      material.name.toLowerCase().includes(gradeFilter.toLowerCase()) ||
      material.description.toLowerCase().includes(gradeFilter.toLowerCase());

    const subjectMatch =
      subjectFilter === "All" ||
      material.file_name2.toLowerCase().includes(subjectFilter.toLowerCase()) ||
      material.file_name1.toLowerCase().includes(subjectFilter.toLowerCase()) ||
      material.name.toLowerCase().includes(subjectFilter.toLowerCase()) ||
      material.description.toLowerCase().includes(subjectFilter.toLowerCase());

    const searchMatch =
      searchInput.trim() === "" ||
      material.file_name2.toLowerCase().includes(searchInput.toLowerCase()) ||
      material.file_name1.toLowerCase().includes(searchInput.toLowerCase()) ||
      material.name.toLowerCase().includes(searchInput.toLowerCase()) ||
      material.description.toLowerCase().includes(searchInput.toLowerCase());

    return gradeMatch && subjectMatch && searchMatch;
  }

  // Corrected handleGradeFilter and handleSubjectFilter functions
  const handleGradeFilter = (e, grade) => {
    e.preventDefault();
    setGradeFilter(grade);
  };

  const handleSubjectFilter = (e, subject) => {
    e.preventDefault();
    setSubjectFilter(subject);
  };

  return (
    <div style={{ width: "90%", margin: "auto" }} className=" mt-2 mb-5">
      <div className="create-community-image-list-container mt-2 mb-1">
        <div>
          <form
            className="sm-mx-1 my-5 my-1 d-fle"
            onSubmit={(e) => e.preventDefault()}
          >
            <div className="row sm-mx-1">
              <div className="col-12">
                <input
                  style={{ width: "100%", maxWidth: "600px" }}
                  className="sm-mx-1 form-control me-sm-2 input"
                  type="search"
                  placeholder="Filter Materials"
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)} // Update search input state
                />
              </div>
            </div>
          </form>

          <div className="subjects-filter-grid my-2">
            <div className="dropdown m-1">
              <button
                className="btn btn-dark dropdown-toggle text-light"
                type="button"
                id="sortDropdown"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
              Select  Grade
              </button>
              <div className="dropdown-menu" aria-labelledby="gradeDropdown">
                <button
                  className="dropdown-item my-1"
                  onClick={(e) => {
                    e.preventDefault();
                    setGradeFilter("All");
                  }}
                >
                  All
                </button>
                <button
                  className="dropdown-item my-1"
                  onClick={(e) => {
                    e.preventDefault();
                    setGradeFilter("10");
                  }}
                >
                  10
                </button>
                <button
                  className="dropdown-item my-1"
                  onClick={(e) => {
                    e.preventDefault();
                    setGradeFilter("11");
                  }}
                >
                  11
                </button>
                <button
                  className="dropdown-item my-1"
                  onClick={(e) => {
                    e.preventDefault();
                    setGradeFilter("12");
                  }}
                >
                  12
                </button>
              </div>
            </div>

            <div className="dropdown m-1">
              <button
                className="btn btn-success dropdown-toggle text-light"
                type="button"
                id="sortDropdown"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
               Select Subject
              </button>
              <div className="dropdown-menu" aria-labelledby="sortDropdown">
                <button
                  className="dropdown-item my-1"
                  onClick={(e) => {
                    e.preventDefault();
                    setSubjectFilter("All");
                  }}
                >
                  All
                </button>
                <button
                  className="dropdown-item my-1"
                  onClick={(e) => {
                    e.preventDefault();
                    setSubjectFilter("math");
                  }}
                >
                  Mathematics
                </button>
                <button
                  className="dropdown-item my-1"
                  onClick={(e) => {
                    e.preventDefault();
                    setSubjectFilter("life scie");
                  }}
                >
                  Life Sciences
                </button>
                <button
                  className="dropdown-item my-1"
                  onClick={(e) => {
                    e.preventDefault();
                    setSubjectFilter("geography");
                  }}
                >
                  Geography
                </button>
              </div>
            </div>
          </div>
        </div>
        <div style={{ maxHeight: "260px", maxWidth: "260px" }} className="my-5">
          <img src={image1} />
        </div>
      </div>
      <div className="mt-5">
        <section className=" mt-1">
          <div>
            {values.subjects?.map((subject) => (
              <div key={subject.name}>
                <div className="subject-materials-container">
                  {subject.materials
                    ?.filter(filterMaterials)
                    ?.map((material) => (
                      <div
                        className="material-container mt-2"
                        key={material.name}
                      >
                        <div className="naming-container text-dark m-1 p-1">
                          <p
                            dangerouslySetInnerHTML={{ __html: material.name }}
                          ></p>
                          
                        </div>
                        <div className="button-container">
                          {material.fileUrl1 && (
                            <a
                              className="btn btn-primary m-1"
                              href={material.fileUrl1}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {material.file_name1.slice(0,30)}
                            </a>
                          )}
                          {material.fileUrl2 && (
                            <a
                              className="btn btn-primary m-1"
                              href={material.fileUrl2}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {material.file_name2.slice(0,30)}
                            </a>
                          )}
                        </div>
                        <div className="d-flex">
                        <small className="text-dark my-2">
                            Uploaded by :{" "}
                            <span className="text-success my-2">
                              {material.displayName}
                            </span>
                          </small>
                        {uid === material.uid && (
                          <div>
                            <p
                              onClick={(e) => confirmDeletion(e, material.id)}
                              className="text-danger m-1"
                            >
                              delete
                            </p>
                          </div>
                        )}
                         </div>
                        <hr />
                      </div>
                    ))}
                </div>
              </div>
            ))}
          </div>
        </section>
      </div>
    </div>
  );
}

export default DisplayMaterials;
