import React, { useRef, useState } from "react";
import {
  projectFirestore,
  auth,
  projectStorage as storage,
} from "../../firebase";
import { useCollectionData } from "react-firebase-hooks/firestore";
import firebase from "firebase/compat/app";
import { useNavigate } from "react-router-dom";
import { v4 } from "uuid";

const GroupsContext = React.createContext();

const GroupsProvider = ({ children }) => {
  
  const route = "materials";
  
  const subjectsRef = projectFirestore.collection(route);
  const query = subjectsRef.orderBy("createdAt");

  const [subjects] = useCollectionData(query, { idField: "id" });

  
  const sendSubject = async (e) => {
    e.preventDefault();


    const id = v4()
    const newSubject = {
      id:id,
      name: "study_materials",
      description: "uploaded_study_materials",
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      materials: [],
    };

    await subjectsRef.add(newSubject);
  };
    
  const addStudyMaterials = async (lesson,  lessonAttributes) => {
    const querySnapshot = await subjectsRef
      .where("name", "==", "study_materials")
      .get();

    if (!querySnapshot.empty) {
      const docRef = querySnapshot.docs[0].ref;

      const storageRef = storage.ref(); // Use your Firebase Storage reference

      const fileRef1 = lessonAttributes.file1
        ? storageRef.child(lessonAttributes.file1.name)
        : null;
      const fileRef2 = lessonAttributes.file2
        ? storageRef.child(lessonAttributes.file2.name)
        : null;
      

      try {
        // Upload the file to Firebase Storage
        if (fileRef1) {
          await fileRef1.put(lessonAttributes.file1);
        }
        if (fileRef2) {
          await fileRef2.put(lessonAttributes.file2);
        }
        
      

        // Get the download URL for the uploaded file
        const fileDownloadURL1 = fileRef1
          ? await fileRef1.getDownloadURL()
          : null;
        const fileDownloadURL2 = fileRef2
          ? await fileRef2.getDownloadURL()
          : null;
        
      
      
        const description = lessonAttributes.description;
       
        const name = lessonAttributes.name;
       

        const file_name1 = lessonAttributes.file1
          ? lessonAttributes.file1.name
          : null;
        const file_name2 = lessonAttributes.file2
          ? lessonAttributes.file2.name
          : null;
        

        const id = v4();

        const { uid, displayName, photoURL, bio, education, modules } =
        auth.currentUser;
        // Assuming lesson is an array of lesson names, you can add attributes to each lesson
        const lessonsWithAttributes = lesson.map((lessonName) => ({
          id,
          uid,
          displayName,
          name,
         
          description,
          
          fileUrl1: fileDownloadURL1, // Add the file URL to the lesson attributes
          fileUrl2: fileDownloadURL2, // Add the file URL to the lesson attributes
          
          file_name1,
          file_name2,
          
        
        }));

        await docRef.update({
          materials: firebase.firestore.FieldValue.arrayUnion(
            ...lessonsWithAttributes
          ),
        });

        alert("added successfully!");
      } catch (error) {
        alert("Error uploading lesson file:", error);
      }
    }
  };

  const deleteMaterials = async (e, material_id) => {
    e.preventDefault();
    const subjectName = "study_materials";

    const querySnapshot = await subjectsRef
      .where("name", "==", subjectName)
      .get();

    if (!querySnapshot.empty) {
      const docRef = querySnapshot.docs[0].ref;
      const subjectData = querySnapshot.docs[0].data();

      // Find the lesson within the subject's lessons array based on its lesson_id
      const lessonIndex = subjectData.materials.findIndex(
        (lesson) => lesson.id === material_id
      );

      if (lessonIndex === -1) {
        alert("Lesson not found.");
        return;
      }

      // Remove the lesson from the lessons array
      subjectData.materials.splice(lessonIndex, 1);

      // Update the subject document with the modified lessons array
      await docRef.update({
        materials: subjectData.materials,
      });

      alert("deleted successfully!");
    } else {
      alert("item not found.");
    }
  };
  
  const values = {
    sendSubject,
    addStudyMaterials,
    subjects,
    deleteMaterials
  };

  return (
    <GroupsContext.Provider value={values}>{children}</GroupsContext.Provider>
  );
};

export { GroupsProvider };

export default GroupsContext;
