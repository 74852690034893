import React, { useContext } from "react";
import DisplayGroup from "./DisplayGroup";
import GroupsContext from "./Contexts/GroupsContext";

function CreateGroup({community_name, community_id}) {

  const values = useContext(GroupsContext);

  return (
    <div className="container xs-container-fluid mt-5 mb-5">
     
      {values.posting ? (
        <div>
          <h6>add post</h6>
        <form onSubmit={(e)=>values.sendSubject(e,community_id,community_name)} className="d-flex flex-column flex-md-row">
          <input
            style={{ width: "200px" }}
            ref={values.nameRef}
            placeholder="Topic Name"
            className="form-control me-sm-2 mb-2 mb-sm-0 m-2 p-2 w-100"
            type="search"
  
          />
          <input
            style={{ width: "200px" }}
  
            ref={values.descriptionRef}
            placeholder="Type your post"
            className="form-control me-sm-2 mb-2 mb-sm-0 m-2 p-2 w-100"
            type="search"
  
          />
          <input
            type="file"
            onChange={values.handleFileChange}
            className="form-control-file mb-2 mb-sm-0 m-2 p-2 w-100"
          />
          <button
            className="btn btn-secondary my-2 my-sm-0 p-2 text-light"
            type="submit"
            disabled={values.isCreating}
           
          >
             {values.isCreating ? "Uploading..." : "Upload"}
          </button>
        </form>
        <button className="btn btn-danger m-3 text-light" onClick={(e)=>values.isPosting(false)}>close</button>
        </div>
      ) : (
        <div>
         <button className="btn btn-primary text-light" onClick={(e)=>values.isPosting(true)}>add post</button>

         </div>
      )}
     
      <main className="subject-wrapper mt-5">
        <DisplayGroup  community_id={community_id} community_name={community_name} />
        <span ref={ values.dummy}></span>
      </main>
    </div>
  )
}

export default CreateGroup
