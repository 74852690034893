import React, { useState, useContext } from "react";
import { Table, Modal, Button } from "react-bootstrap";
import ChallengesSubmission from "./ChallengesSubmission";
import ChallengeReviewForm from "./ChallengeReviewForm";
import { auth } from "../firebase";
import CommunityContext from "./Contexts/CommunityContext";
import SubjectsContext from "./Contexts/SubjectContext";
import ReactPlayer from "react-player";
import StarIcons from "./StarIcons";

function Challenges({ challenges, type, subject_id, lesson_id }) {

  const [selectedChallenge, setSelectedChallenge] = useState(null);
  const [showResponseForm, setShowResponseForm] = useState(false);
  const [showReviewForm, setShowReviewForm] = useState(false);

  let cont;

  if (type === "community") {
    cont = CommunityContext;
  } else {
    cont = SubjectsContext;
  }

  const values = useContext(cont);

  const openModal = (index) => {
    setSelectedChallenge(challenges[index]);
  };

  const closeModal = () => {
    setSelectedChallenge(null);
    setShowResponseForm(false); // Resetting the response form visibility
  };

  const toggleResponseForm = () => {
    setShowResponseForm(!showResponseForm); // Toggling the response form visibility
  };

  const calculateAverageScore = (reviews) => {
    if (reviews && reviews.length > 0) {
      const totalScore = reviews.reduce((acc, review) => acc + review.score, 0);
      return totalScore / reviews.length;
    }
    return 0;
  };

  const { uid, displayName, photoURL, bio, education, modules } = auth.currentUser;

  const getFileType = (fileUrl) => {
    const extension = fileUrl.split(".").pop().toLowerCase();
    const imageExtensions = ["jpg", "jpeg", "png", "gif"];
    const audioExtensions = ["mp3", "wav", "ogg"];
    const videoExtensions = ["mp4", "avi", "mkv", "mov"];
    const documentExtensions = [
      "pdf",
      "doc",
      "docx",
      "xls",
      "xlsx",
      "ppt",
      "pptx",
    ];

    if (imageExtensions.includes(extension)) {
      return "Image";
    } else if (audioExtensions.includes(extension)) {
      return "Audio";
    } else if (videoExtensions.includes(extension)) {
      return "Video";
    } else if (documentExtensions.includes(extension)) {
      return "Document";
    } else {
      return "File";
    }
  };

  const getResponseBackgroundColor = (index) => {
    return index % 2 === 0 ? "light" : " ";
  };

  const getResponseTextColor = (index) => {
    return index % 2 === 0 ? " " : " ";
  };

  return (
    <div className="my-5 table-responsive">
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>#</th>
            <th>Question</th>
            <th>Status</th>
            <th>visit</th>
          </tr>
        </thead>
        <tbody>
          {challenges?.map((challenge, index) => {
            const completedByCurrentUser = challenge.responses.some(
              (response) => response.displayName === displayName
            );
            return (
              <tr
                key={index}
                onClick={() => openModal(index)}
                style={{ cursor: "pointer" }}
              >
                <td>{index + 1}</td>
                <td dangerouslySetInnerHTML={{ __html: challenge.topic }}></td>
                <td>
                  {completedByCurrentUser ? (
                    <p className="text-success">answered</p>
                  ) : (
                    <p className="text-danger">not answered</p>
                  )}
                </td>
                <td>
                  <button className="bg-dark text-light p-1">Read</button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>

      {/* Modal */}
      <Modal
        show={selectedChallenge !== null}
        onHide={closeModal}
        ClassName="custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Details</Modal.Title>
        </Modal.Header>
        {selectedChallenge && (
          // Inside the Challenges component, within the Modal's Body section
          <Modal.Body>
            {selectedChallenge && (
              <div>
                
                <section>
                  <p>
                    <strong>Question:</strong>{" "}
                    <span
                      dangerouslySetInnerHTML={{
                        __html: selectedChallenge.topic,
                      }}
                    />
                  </p>

                  <p>
                    
                    <a
                      href={selectedChallenge.fileUrl1}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {selectedChallenge.file_name1}
                    </a>
                    <p>
                      {selectedChallenge.fileUrl1 && (
                        <>
                          {getFileType(selectedChallenge.file_name1) ===
                            "Audio" ? (
                            <div>
                              <ReactPlayer
                                url={selectedChallenge.fileUrl1}
                                controls
                                width="100%"
                                height="50px"
                              />
                            </div>
                          ) : getFileType(selectedChallenge.file_name1) ===
                            "Video" ? (
                            <div>
                              <ReactPlayer
                                url={selectedChallenge.fileUrl1}
                                controls
                                width="100%"
                                max-width="400px"
                                height="auto"
                              />
                            </div>
                          ) : getFileType(selectedChallenge.file_name1) ===
                            "Image" ? (
                            <div>
                              <img src={selectedChallenge.fileUrl1} alt="..." />
                            </div>
                          ) : getFileType(selectedChallenge.file_name1) ===
                            "Document" ? (
                            <div>
                              <a
                                href={selectedChallenge.fileUrl1}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {selectedChallenge.file_name1}
                              </a>
                            </div>
                          ) : (
                            <p>Invalid file</p>
                          )}
                        </>
                      )}
                    </p>
                  </p>
                  {displayName === selectedChallenge.name && (
                    <p
                      onClick={(e) =>
                        values.deleteChallengeFromSubject(
                          e,
                          selectedChallenge.subject_id,
                          selectedChallenge.lesson_id
                        )
                      }
                      className="btn btn-outline-danger my-3"
                    >
                      delete
                    </p>
                  )}

                  <hr />
                </section>
                <section>
                  <button
                    className="mt-3 btn btn-dark py-2 px-2"
                    onClick={toggleResponseForm}
                  >
                    {showResponseForm
                      ? "Close Answer Form"
                      : "Send Your Answer"}
                  </button>

                  {/* Show response submission form based on visibility */}
                  {showResponseForm && (
                    <div>
                      {/* Your submission form goes here */}
                      <ChallengesSubmission
                        subjectName={selectedChallenge?.subject_id}
                        challengeId={selectedChallenge?.lesson_id}
                        type={type}
                      />
                    </div>
                  )}
                </section>
                <h5 className="fw-bolder text-success  mt-5">Available answers</h5>
                <p className="text-dark  my-1">
                  Write your review to available answers
                </p>
                <section className="my-3">
                  {selectedChallenge.responses?.map(
                    (response, responseIndex) => (
                      <div
                        className={`my-5 p-3 bg-${getResponseBackgroundColor(
                          responseIndex
                        )} ${getResponseTextColor(responseIndex)}`}
                        key={responseIndex}
                      >
                        <details>
                          <summary>
                            <strong>
                              <h6 className="fw-bolder">
                                {responseIndex + 1}: {response.displayName}
                                <span className="text-primary">
                                  {" "}
                                  Average Score:{" "}
                                  {calculateAverageScore(response.reviews)}
                                </span>

                              </h6>
                            </strong>
                          </summary>
                          <div>
                            <p>
                              <p>
                                <strong>Answer:</strong>{" "}
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: response.summary,
                                  }}
                                />
                              </p>

                            </p>
                            {displayName === response.displayName && (
                              <p onClick={(e) => values.removeResponseFromChallenge(e, selectedChallenge?.subject_id, selectedChallenge?.lesson_id, response.responseId)} className="btn btn-outline-danger my-3 py-2 px-3">delete your answer</p>
                            )}

                            {/* Dropdown for Reviews */}
                            <details className="my-4 mb-5">
                              <summary className="fw-bolder text-success">
                                Show Reviews
                              </summary>
                              <div>
                                {response.reviews?.map(
                                  (review, reviewIndex) => (
                                    <div className="my-5" key={reviewIndex}>
                                      <p>
                                        <p className="fw-bolder text-primary">
                                          {reviewIndex + 1}:{" "}
                                          {review?.displayName}
                                        </p>
                                      </p>
                                      <p>
                                        <p>Score: {review?.score}</p>
                                        <StarIcons averageRating={review?.score} />
                                      </p>
                                      <p
                                        dangerouslySetInnerHTML={{
                                          __html: `<p><strong>Review:</strong> ${review?.summary}</p>`,
                                        }}
                                      />

                                      <hr />
                                    </div>
                                  )
                                )}
                              </div>
                            </details>

                            <div>
                              {/* ChallengeReviewForm for Response */}
                              <section>
                                <Button
                                  onClick={() =>
                                    setShowReviewForm(
                                      responseIndex,
                                      !showReviewForm
                                    )
                                  }
                                  className="my-3"
                                >
                                  {showReviewForm === responseIndex
                                    ? "Review Form"
                                    : "Write Review"}
                                </Button>
                                {showReviewForm === responseIndex && (
                                  <div>
                                    <h6 className="my-4">Write Your Review</h6>
                                    {/* Your ChallengeReviewForm goes here */}
                                    <ChallengeReviewForm
                                      subjectName={
                                        selectedChallenge?.subject_id
                                      }
                                      challengeId={selectedChallenge?.lesson_id}
                                      responseId={response?.responseId}
                                      type={type}
                                    />
                                  </div>
                                )}
                              </section>
                            </div>
                          </div>
                        </details>
                        <hr />
                      </div>
                    )
                  )}
                </section>
              </div>
            )}
          </Modal.Body>
        )}
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Challenges;
