import React, { useContext, useState } from "react";
import GroupsContext from "./Contexts/GroupsContext"
import { v4 } from "uuid";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import the styles


function UploadMaterials({ subjectId }) {

    const values = useContext(GroupsContext);

    const [isCreating, setIsCreating] = useState(false);

    const [lesson, setLesson] = useState([]);
    const id = v4();
    const [file_name1, setFileName1] = useState("");
    const [file_name2, setFileName2] = useState("");

    const [lessonAttributes, setLessonAttributes] = useState({
       
        description: "",
       
        name: "",
        id,
       
        file1: null,
        file_name1,

        file2: null,
        file_name2,

    });



    const handleAddLesson = () => {
        setLesson([...lesson, ""]);
    };


    const handleLessonAttributeChange = (value, attributeName) => {
        setLessonAttributes((prevAttributes) => ({
            ...prevAttributes,
            [attributeName]: value,
        }));
    };

    const handleFileChange1 = (e) => {
        const file1 = e.target.files[0];
        const fileName = file1 ? file1.name : ""; // Extract the file name
        setFileName1(fileName);
        setLessonAttributes({ ...lessonAttributes, file1, file_name1: fileName });
    };

    const handleFileChange2 = (e) => {
        const file2 = e.target.files[0];
        const fileName = file2 ? file2.name : ""; // Extract the file name
        setFileName2(fileName);
        setLessonAttributes({ ...lessonAttributes, file2, file_name2: fileName });
    };





    const handleLessonSave = async () => {
        handleAddLesson();
        if (lesson.length === 0) {
            return;
        }
        // Check if file1 and file2 are selected
    if (!lessonAttributes.file1 || !lessonAttributes.file2) {
        alert("Please select both files before uploading.");
        return;
    }

    setIsCreating(true); // Set loading state to true
        try {
            // Pass lesson attributes like topic, description, summary, and file to your addLessonToSubject function.
            await values.addStudyMaterials(lesson ,lessonAttributes);

            // Clear the fields after saving
            setLesson([]);
            setLessonAttributes({
               
                description: "",
                
                name: "",
                id: "",
              
                file1: null,
                file2: null,

            });

            // Clear the file name states
            setFileName1("");
            setFileName2("");


            setIsCreating(false); // Set loading state to false after success
            alert("Lesson added successfully!");
        } catch (error) {
            setIsCreating(false); // Set loading state to false if there's an error
            alert("Error adding lesson:", error.message);
        }
    };

    return (
        <div style={{maxWidth:"800px"}} className="my-5  container">
            <h6 className="my-4">Upload any learning materials you wish to share</h6>
            <p className="my-2 text-info">Input any descriptive infomation about the material in the inputs below</p>
            <ReactQuill
                type="text"
                name="name"
                value={lessonAttributes.name}
                onChange={(e) => handleLessonAttributeChange(e, "name")}
                placeholder="Enter Name , e.g Mathematics Grade 12 Paper 1"
                className="react-quill m-2 my-5"
            />

            
            <ReactQuill
                type="text"
                name="description"
                value={lessonAttributes.description}
                onChange={(e) => handleLessonAttributeChange(e, "description")}
                placeholder="Description . e.g Question Paper , Limpopo"
                className="react-quill m-2 my-5"
            />

           
            
            <h6>Select Files</h6>

            <input
                type="file"
                name="file1"
                onChange={handleFileChange1}
                className="m-2 my-4"
                required
            />

            <input
                type="file"
                name="file2"
                onChange={handleFileChange2}
                className="m-2 my-4"
                required
            />


            <button
                className="btn btn-success m-2"
                onClick={handleLessonSave}
                disabled={isCreating}
            >
                {isCreating ? "Uploading..." : "click twice to upload"}
            </button>
        </div>
    );
}

export default UploadMaterials;

