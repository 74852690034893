import React from 'react'
import { useParams, useLocation } from 'react-router-dom'
import DisplayContent from './DisplayContent'

function Lesson() {

  const { lesson } = useParams()
  const createQuiz = false

  const location = useLocation();
  const { data } = location.state;



  return (
    <>
      <div className='mt-5 mb-2'>
        <DisplayContent
          lesson_info={data}
          lesson_id={lesson}
          createQuiz={createQuiz}
          route={`${lesson}/content/c/lesson/l`} />
      </div>
    </>
  )
}

export default Lesson
