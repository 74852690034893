import React, { useRef, useState, useContext, useEffect } from "react";
import { projectFirestore, auth, projectStorage as storage } from "../firebase";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import firebase from "firebase/compat/app";
import ReactPlayer from "react-player";
import { v4 } from "uuid";
import SubjectsContext from "./Contexts/SubjectContext";
import MessageRepliesPopup from "./MessageRepliesPopup";
import CommunityContext from "./Contexts/CommunityContext";
import moment from "moment";

function Message({ path, type }) {
  return (
    <div className="chat mt-5 mb-5">
      <section>
        <ChatRoom path={path} type={type} />
      </section>
    </div>
  );
}

function ChatRoom({ path, type }) {
  let cont;

  if (type === "community") {
    cont = CommunityContext;
  } else {
    cont = SubjectsContext;
  }

  const values = useContext(cont);

  const subjectMessages = values.subjects?.filter(
    (subject) => subject.name == path
  );

  const subject_messages = subjectMessages[0]?.messages;

  const dummy = useRef();

  const messagesRef = projectFirestore.collection(`${path}`);

  const query = messagesRef.orderBy("createdAt");

  const [messages] = useCollectionData(query, { idField: "id" });

  const [formValue, setFormValue] = useState("");
  const [file, setFile] = useState(null);

  const [loading, setLoading] = useState(false);

  const sendMessage = async (e) => {
    e.preventDefault();
    setLoading(true);
    const maxSizeInBytes = 20 * 1024 * 1024;
    const { uid, displayName, photoURL } = auth.currentUser;

    if (file && file.size > maxSizeInBytes) {
      alert("file is too large, maximum file size is 20mb");
      document.getElementById("fileInput").value = "";
    } else {
      const newMessage = {
        id: v4(),
        text: formValue,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        uid,
        displayName,
        photoURL,
      };

      if (file) {
        const storageRef = storage.ref();
        const fileRef = storageRef.child(file.name);
        await fileRef.put(file);
        const downloadURL = await fileRef.getDownloadURL();

        newMessage.file = {
          name: file.name,
          url: downloadURL,
        };
      }

      if (selectedMessage && formValue !== "") {
        const replyMessage = {
          id: v4(),
          text: formValue,
          uid,
          displayName,
          subjectName: path,
          messageId: selectedMessage.id,
          photoURL,
        };
        if (file) {
          const storageRef = storage.ref();
          const fileRef = storageRef.child(file.name);
          await fileRef.put(file);
          const downloadURL = await fileRef.getDownloadURL();

          replyMessage.file = {
            name: file.name,
            url: downloadURL,
          };
        }

        // Add the reply message to the response array of the selected message
        values.addResponseToMessage(path, selectedMessage.id, replyMessage);

        setFormValue("");
        setFile(null);
        document.getElementById("fileInput").value = "";
        // dummy.current.scrollIntoView({ behavior: "smooth" });

        // Clear the selected message after sending the reply.
        setSelectedMessage(null);
        setLoading(false);
        return;
       
      }

      await values.addMessagesToSubject(newMessage, path);
    }

    setFormValue("");
    setFile(null);
    document.getElementById("fileInput").value = "";
    //dummy.current.scrollIntoView({ behavior: "smooth" });

    // Clear the selected message after sending the reply.
    setSelectedMessage(null);
    setLoading(false);
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
  };

  const handleReply = (message) => {
    //setFormValue(message.text);
    setSelectedMessage(message);
  };

  const handleCancelReply = (e) => {
    e.preventDefault();
    setSelectedMessage(null);
    setFormValue(e.target.value);
    setFile(null);
    document.getElementById("fileInput").value = "";
  };

  const [showPopup, setShowPopup] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState(null);

  const handleRepliesClick = (message) => {
    setSelectedMessage(message);
    setShowPopup(true);
  };

  // Function to close the popup
  const handleClosePopup = () => {
    setShowPopup(false);
    setSelectedMessage(null);
  };

  return (
    <>
      <div style={{ width: "100%",maxWidth:"1000px" }} className="messages bg-dark p-1 mb-4">
        {subject_messages &&
          subject_messages.map((msg) => (
            <>
              <ChatMessage
                type={type} // Pass the 'type' prop
                path={path} // Pass the 'path' prop
                key={msg.id}
                message={msg}
                selectedMessage={selectedMessage}
                setSelectedMessage={setSelectedMessage}
                onReply={handleReply}
                onRepliesClick={handleRepliesClick}
              />
            </>
          ))}
      </div>

      {showPopup && selectedMessage && (
        <MessageRepliesPopup
          selectedMessage={selectedMessage}
          onClose={handleClosePopup}
        />
      )}
      <form onSubmit={sendMessage} className="d-fle flex-column flex-md-row">
        <div className="my-2">
          <p>{selectedMessage && selectedMessage.text}</p>
        </div>
        <input
          style={{ maxWidth: "500px", width: "100%" }}
          value={formValue}
          onChange={(e) => setFormValue(e.target.value)}
          placeholder="Say something nice"
          className="form-control me-sm-2 mb-2 mb-sm-0 p-2"
          type="search"
        />
        <input
          type="file"
          id="fileInput"
          onChange={handleFileChange}
          className="form-control-file mb-2 mb-sm-0 p-2"
        />
        {selectedMessage && (
          <button
            style={{ maxWidth: "500px" }}
            className="btn btn-outline-dark my-2 my-sm-0 mx-1"
            onClick={(e) => handleCancelReply(e)}
            disabled={!formValue || loading}
          >
            {loading ? "Loading..." : "Cancel Reply"}
          </button>
        )}
        <button
          style={{ maxWidth: "500px" }}
          className="btn btn-success my-2 my-sm-0 mx-1 "
          type="submit"
          disabled={!formValue || loading}
        >
          {loading ? "Loading..." : "Send message"}
        </button>
      </form>
    </>
  );
}

function ChatMessage(props) {
  const [user] = useAuthState(auth);

  const {
    text,
    timestamp,
    messageId,
    id,
    displayName,
    photoURL,
    fileName,
    fileUrl,
  } = props.message;
  const { type, path } = props;

  let cont;

  if (type === "community") {
    cont = CommunityContext;
  } else {
    cont = SubjectsContext;
  }

  const values = useContext(cont);

  const getFileType = (fileUrl) => {
    const extension = fileUrl.split(".").pop().toLowerCase();
    const imageExtensions = ["jpg", "jpeg", "png", "gif"];
    const audioExtensions = ["mp3", "wav", "ogg"];
    const videoExtensions = ["mp4", "avi", "mkv", "mov"];
    const documentExtensions = [
      "pdf",
      "doc",
      "docx",
      "xls",
      "xlsx",
      "ppt",
      "pptx",
    ];

    if (imageExtensions.includes(extension)) {
      return "Image";
    } else if (audioExtensions.includes(extension)) {
      return "Audio";
    } else if (videoExtensions.includes(extension)) {
      return "Video";
    } else if (documentExtensions.includes(extension)) {
      return "Document";
    } else {
      return "File";
    }
  };

  const [showReplies, setShowReplies] = useState(false);

  const toggleShowReplies = () => {
    setShowReplies(!showReplies);
  };

  const renderRepliesDropdown = () => {
    const responses = props.message.responses || [];

    return (
      <div className="replies-dropdown">
        {responses.length > 0 && (
          <p className="text-warning mx-1" onClick={toggleShowReplies}>
            {showReplies
              ? `Hide Replies ${responses?.length}`
              : `Show Replies ${responses?.length}`}
          </p>
        )}

        {showReplies && (
          <ul>
            {responses.map((response) => (
              <li key={response.id}>
                <p>
                  <span className="text-success">@{response.displayName} </span>{" "}
                  - {response.text}
                </p>
                {/* Display other response details as needed */}
                <div className=" mt-1 mb-1 text-align-end">
                  {response.file && (
                    <div className="message-file">
                      <p className="text-align-start">
                        {getFileType(response.file.name) === "Audio" ? (
                          <div>
                            <ReactPlayer
                              url={response.file.url}
                              controls
                              width="100%"
                              height="50px"
                            />
                          </div>
                        ) : getFileType(response.file.name) === "Video" ? (
                          <div>
                            <ReactPlayer
                              url={response.file.url}
                              controls
                              width="100%"
                              max-width="400px"
                              height="auto"
                            />
                          </div>
                        ) : getFileType(response.file.name) === "Image" ? (
                          <div>
                            <img src={response.file.url} alt="..." />
                          </div>
                        ) : getFileType(response.file.name) === "Document" ? (
                          <div>
                            <a
                              href={response.file.url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {response.file.name}
                            </a>
                          </div>
                        ) : (
                          <p>Invalid file</p>
                        )}
                      </p>
                    </div>
                  )}
                </div>
                {response.displayName === user.displayName && (
                  <span
                    onClick={(e) =>
                      values.deleteResponseFromMessage(
                        e,
                        response.subjectName,
                        response.messageId,
                        response.id
                      )
                    }
                    className="text-danger"
                  >
                    delete
                  </span>
                )}
                <hr />
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  };

  const handleReplyClick = () => {
    if (props.onReply) {
      props.onReply(props.message);
    }
  };
  const timeAgo = moment(timestamp.toDate()).fromNow();
  const formattedTimestamp = moment(timestamp.toDate()).format("LLL");
  return (
    <div  className="chat-message mt-1 mb-1">
      <div className={`message  text-display`}>
        <div className="profile-pic-message-chat">
          <div className="profile-pic p-2">
            <i className="fas fa-user"></i>
          </div>
          <div className="py-1">
            <h6 className="text-warning">{displayName && displayName}</h6>
            <small className="text-success">{`sent at ${
              formattedTimestamp && formattedTimestamp
            }, ${timeAgo && timeAgo}`}</small>
            <p dangerouslySetInnerHTML={{ __html: text && text }}>
              {/*text && text*/}
            </p>
          </div>
        </div>

        <div className=" mt-1 mb-1 text-align-end">
          {fileUrl && (
            <div className="message-file">
              <p className="text-align-start">
                {getFileType(fileName) === "Audio" ? (
                  <div>
                    <ReactPlayer
                      url={fileUrl}
                      controls
                      width="100%"
                      height="50px"
                    />
                  </div>
                ) : getFileType(fileName) === "Video" ? (
                  <div>
                    <ReactPlayer
                      url={fileUrl}
                      controls
                      width="100%"
                      max-width="400px"
                      height="auto"
                    />
                  </div>
                ) : getFileType(fileName) === "Image" ? (
                  <div>
                    <img src={fileUrl} alt="..." />
                  </div>
                ) : getFileType(fileName) === "Document" ? (
                  <div>
                    <a href={fileUrl} target="_blank" rel="noopener noreferrer">
                      {fileName}
                    </a>
                  </div>
                ) : (
                  <p>Invalid file</p>
                )}
              </p>
            </div>
          )}
        </div>
        <div className="d-flex">
          {renderRepliesDropdown()}{" "}
          <p className="text-primary mx-1" onClick={handleReplyClick}>
            Reply
          </p>{" "}
          {user.displayName === displayName ? (
            <>
              <p
                className="text-danger mx-1"
                onClick={(e) =>
                  values.deleteMessageFromSubject(e, path, messageId)
                }
              >
                delete
              </p>
            </>
          ) : (
            ""
          )}{" "}
        </div>
      </div>
    </div>
  );
}

export default Message;
