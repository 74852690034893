import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/storage";
import "firebase/compat/firestore";
import "firebase/compat/functions"; // Add this line

const app = firebase.initializeApp({
  apiKey: "AIzaSyDHR6frvpa4cSUK8mBGYQyECsqRS_o5Xek",
  authDomain: "test-database-a0ede.firebaseapp.com",
  projectId: "test-database-a0ede",
  storageBucket: "test-database-a0ede.appspot.com",
  messagingSenderId: "50241609239",
  appId: "1:50241609239:web:220f43418ef70377218652"
});

export const auth = app.auth();
export default app;

const projectStorage = firebase.storage();
const projectFirestore = firebase.firestore();
const projectFunctions = firebase.functions(); // Add this line
const timestamp = firebase.firestore.FieldValue.serverTimestamp;

export { projectStorage, projectFirestore, projectFunctions, timestamp }; // Export projectFunctions
