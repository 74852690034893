import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import Authentication from "./FinalProject/Authentication/Authentication";
import { SubjectsProvider } from "./FinalProject/Pages/Contexts/SubjectContext";
import { GroupsProvider } from "./FinalProject/Pages/Contexts/GroupsContext";
import { CommunityProvider } from "./FinalProject/Pages/Contexts/CommunityContext";
import { UsersProvider } from "./FinalProject/Pages/Contexts/UsersContext";

function App() {
  return (
    <div className="components">
      <Router>
        <SubjectsProvider>
          <CommunityProvider>
            <GroupsProvider>
              <UsersProvider>
                <Authentication />
              </UsersProvider>
            </GroupsProvider>
          </CommunityProvider>
        </SubjectsProvider>
      </Router>
    </div>
  );
}

export default App;
