import React, { useContext, useState, useEffect } from "react";
import { projectFirestore, auth } from "../firebase";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link } from "react-router-dom";
import SubjectsContext from "./Contexts/SubjectContext";
import Challenges from "./Challenges";
import CreateChallenges from "./CreateChallenges";
import Message from "./Message";
import Quizzes from "./Quizzes";
import QuizScores from "./QuizScores";
import CreateQuizQuestion from "./CreateQuizQuestion";

function DisplayLesson({ subject, route }) {
  const [user] = useAuthState(auth);
  const values = useContext(SubjectsContext);
  const [createChallenges, setCreateChallenges] = useState(false);
  const [openClasses, setOpenClasses] = useState(true);
  const [openQuestions, setOpenQuestions] = useState(false);
  const [openChats, setOpenChats] = useState(false);
  const [openQuizzes, setOpenQuizzes] = useState(false);
  
  const [studentRankings, setStudentRankings] = useState([]);
  const [learningOutcomesOpen, setLearningOutcomesOpen] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
  }, []);

  const subjectsRef = projectFirestore.collection(route);
  const query = subjectsRef.orderBy("createdAt");

  const [lessons, loading] = useCollectionData(query, { idField: "id" });

  const toggleSection = (section) => {
    setOpenClasses(section === "classes");
    setOpenQuestions(section === "questions");
    setOpenChats(section === "chats");
    setOpenQuizzes(section === "quizzes");
  };

  let isDisabled = true;
  const getSubject = values.subjects?.filter((sub) => sub.name === subject);

  const totalLessons = getSubject?.[0]?.lessons?.length || 0;
  const lessonsCompleted = getSubject?.[0]?.lessons?.filter((lesson) =>
    lesson.students?.includes(user.displayName)
  ).length || 0;

  useEffect(() => {
    if (getSubject && getSubject[0]) {
      const studentRankingsObj = {};
      getSubject[0].lessons?.forEach((lesson) => {
        lesson.students?.forEach((student) => {
          if (studentRankingsObj[student]) {
            studentRankingsObj[student]++;
          } else {
            studentRankingsObj[student] = 1;
          }
        });
      });

      const rankingsArray = Object.keys(studentRankingsObj).map((student) => ({
        student,
        lessonsIncluded: studentRankingsObj[student],
      }));

      rankingsArray.sort((a, b) => b.lessonsIncluded - a.lessonsIncluded);

      setStudentRankings(rankingsArray);
    }
  }, [getSubject]);

  if (loading) {
    return <div>Loading lessons...</div>;
  }

  if (!getSubject || !getSubject[0]) {
    return <div>No subject found or the data is still loading.</div>;
  }

  const getEnrolled = getSubject?.map((sub) =>
    sub.students?.includes(user.displayName)
  );

  if (getEnrolled && getEnrolled[0] === true) {
    isDisabled = false;
  }

  if (getSubject[0]?.displayName === user.displayName) {
    isDisabled = false;
  }

  return (
    <>
      <div>
        <section className="py-2 mt-1 mb-1">
          <div className="d-flex flex-wrap gap-3">
            <button
              onClick={() => toggleSection("classes")}
              className="btn btn-success text-light mx-2 my-3 mb-5"
            >
              Classes
            </button>
            <button
              onClick={() => toggleSection("chats")}
              className="btn btn-dark text-light mx-1 my-3 mb-5"
            >
              Chats
            </button>
            <button
              onClick={() => toggleSection("questions")}
              className="btn btn-primary text-light mx-1 my-3 mb-5"
            >
              Questions
            </button>
            <button
              onClick={() => toggleSection("quizzes")}
              className="btn btn-outline-dark  mx-1 my-3 mb-5"
            >
              Quizzes
            </button>
          </div>

          {openClasses && (
            <div>
              <h5 className="mb-3 text-success">Classes</h5>
              <div className="progress my-5">
                <div
                  className="progress-bar text-success"
                  role="progressbar"
                  style={{
                    width: `${(lessonsCompleted / totalLessons) * 100}%`,
                  }}
                  aria-valuenow={lessonsCompleted}
                  aria-valuemin={0}
                  aria-valuemax={totalLessons}
                >
                  {`${lessonsCompleted} of ${totalLessons} Lessons Completed`}
                </div>
              </div>

              <div className="mx-2 my-3">
                {getSubject?.[0]?.lessons?.map((lesson, index) => (
                  <div className="my-5 lesson-delete" key={index}>
                    <p className="fw-bolder">
                      Class {index + 1} -{" "}
                      {lesson.students?.includes(user.displayName) ? (
                        <span className="text-success">Completed</span>
                      ) : (
                        <span className="text-warning">Incomplete</span>
                      )}
                    </p>
                    <br />
                    <Link
                      to={`${lesson.l_id}`}
                      state={{ data: lesson }}
                      style={{ textDecoration: "none" }}
                      className={`my-div ${
                        index > 1 && isDisabled ? "disabled" : ""
                      }`}
                    >
                      <div className="col ">
                        <div
                          className={`btns ${
                            lesson.students?.includes(user.displayName)
                              ? "texts-success"
                              : "texts-primary"
                          } w-100`}
                        >
                          <div className="card-bod p-1">
                            <div>
                              <div className="text-start">
                                <p className="fw-bolder text-dark">
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: lesson.topic,
                                    }}
                                  ></div>
                                </p>
                                <hr />
                              </div>
                              <div className="text-start">
                                <p>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: lesson.description.slice(0, 300),
                                    }}
                                  ></div>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <button className="mt-3 p-2 bg-dark">
                          <Link
                            to={`${lesson.l_id}`}
                            state={{ data: lesson }}
                            style={{ textDecoration: "none" }}
                            className={`my-div ${
                              index > 1 && isDisabled ? "disabled" : ""
                            }`}
                          >
                            Open Class Content
                          </Link>
                        </button>
                      </div>
                    </Link>
                    {user.displayName === getSubject[0]?.displayName && (
                      <button
                        onClick={(e) =>
                          values.deleteLesson(
                            e,
                            getSubject[0].sub_id,
                            lesson.lesson_id
                          )
                        }
                        className="btn btn-outline-danger my-2"
                      >
                        <i className="fas fa-trash"></i>
                      </button>
                    )}
                    <hr />
                  </div>
                ))}
              </div>
            </div>
          )}

          {openQuestions && (
            <div className="container my-3">
              <p className="mx-1 my-5 fw-bolder text-primary">Questions</p>
              
              {createChallenges ? (
                <div className="my-2">
                  <button
                    onClick={() => setCreateChallenges(false)}
                    className="btn btn-dark text-light my-3"
                  >
                    Close send question
                  </button>
                  <CreateChallenges subjectId={getSubject[0]?.name} type="subject" />
                </div>
              ) : (
                <button
                  onClick={() => setCreateChallenges(true)}
                  className="btn btn-success text-light py-2 px-2 my-3 mb-5"
                >
                  Send question
                </button>
              )}
              <Challenges challenges={getSubject[0]?.challenges} type="subject" />
            </div>
          )}

          {openQuizzes && (
            <div className="container my-3">
              
              <p className="mx-1 my-5 fw-bolder text-info">Quizzes</p>
              <Quizzes data={getSubject[0]?.quizzes} type="subject" />
              <hr />
              <div className="container my-3">
                <p className="mx-1 my-5 fw-bolder">Quiz Scores</p>
                <QuizScores subject={getSubject[0]?.name} students={getSubject[0]?.students} />
              </div>
            </div>
          )}

          {openChats && (
            <div className="container my-3">
              
              <p className="mx-1 my-5 fw-bolder text-dark">Chats</p>
              <Message path={getSubject[0]?.name} type="subject" />
            </div>
          )}
        </section>
      </div>
    </>
  );
}

export default DisplayLesson;
