import React, { useState, useEffect, useContext } from "react";
import { projectFirestore as firestore } from "../firebase";
import { UsersContext } from "./Contexts/UsersContext";

const QuizScores = ({ grade, subject, students, topic }) => {
  const { users } = useContext(UsersContext);

  const [userScores, setUserScores] = useState([]);

  useEffect(() => {
    const fetchUserScores = async () => {
      try {
        const usersSnapshot = users?.filter((user) =>
          students?.includes(user.displayName)
        );

        const allUserScores = [];

        usersSnapshot.forEach((userDoc) => {
          const userData = userDoc;
          const answeredQuestionsLog = userData.answeredQuestionsLog || [];

          const filteredAnsweredQuestions = answeredQuestionsLog.flatMap(
            (log) =>
              log.quizResults.filter(
                (answeredQuestion) =>
                 // answeredQuestion.topic === topic &&
                  answeredQuestion.subject === subject
              )
          );

          const totalAnswered = filteredAnsweredQuestions.length;
          const totalCorrect = filteredAnsweredQuestions.filter(
            (answeredQuestion) => answeredQuestion.isCorrect
          ).length;

          const percentage =
            totalAnswered > 0 ? (totalCorrect / totalAnswered) * 100 : 0;

          allUserScores.push({
            userId: userDoc.id,
            name: userData.displayName,
            photoURL: userData.photoURL,
            school: userData.school,
            totalAnswered,
            totalCorrect,
            percentage,
          });
        });

        setUserScores(allUserScores);
      } catch (error) {
        console.error("Error fetching user scores:", error.message);
      }
    };

    fetchUserScores();
  }, [students, subject]);

  return (
    <div className="my-2">
      <h5 className="my-4 text-primary">Quiz Scores</h5>
      <table>
        <thead>
          <tr>
            <th>image</th>
            <th>Name</th>
            <th>School</th>
            <th>Answered</th>
            <th>Correct</th>
            <th>Percentage</th>
          </tr>
        </thead>
        <tbody>
          {userScores.map((userScore) => (
            <tr key={userScore.userId}>
              <td>
                {userScore.photoURL ? (
                  <div className="profile-pics">
                    <img src={userScore.photoURL} alt="" />
                  </div>
                ) : (
                  <i className="fas fa-user"></i>
                )}
              </td>
              <td>{userScore.name}</td>
              <td>{userScore.school}</td>
              <td>{userScore.totalAnswered}</td>
              <td>{userScore.totalCorrect}</td>

              <td>
                <span
                  className={
                    userScore.percentage < 40
                      ? "text-danger"
                      : userScore.percentage < 70
                      ? "text-primary"
                      : "text-success"
                  }
                >
                  {(
                    (userScore.totalCorrect / userScore.totalAnswered) *
                    100
                  ).toFixed(2)}
                  %
                </span>{" "}
                {/* Good, Excellent, etc. */}
                {(
                  (userScore.totalCorrect / userScore.totalAnswered) *
                  100
                ).toFixed(2) < 40
                  ? "Poor"
                  : (
                      (userScore.totalCorrect / userScore.totalAnswered) *
                      100
                    ).toFixed(2) < 70
                  ? "Average"
                  : "Excellent"}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default QuizScores;
