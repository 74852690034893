import React, { useRef, useState } from "react";
import {
  projectFirestore,
  auth,
  projectStorage as storage,
} from "../../firebase";
import { useCollectionData } from "react-firebase-hooks/firestore";
import firebase from "firebase/compat/app";
import { useNavigate } from "react-router-dom";
import { v4 } from "uuid";

const SubjectsContext = React.createContext();

const SubjectsProvider = ({ children }) => {
  const navigate = useNavigate();
  const path = "subjects";
  const dummy = useRef();
  const nameRef = useRef();
  const reviewRef = useRef();
  const descriptionRef = useRef();
  const priceRef = useRef();

  const subjectsRef = projectFirestore.collection(path);
  const query = subjectsRef.orderBy("createdAt");

  const [subjects] = useCollectionData(query, { idField: "id" });

  const [formValue, setFormValue] = useState("");
  const [file, setFile] = useState(null);
  const [isCreating, setIsCreating] = useState(false);

  const sendSubject = async (e) => {
    e.preventDefault();

    setIsCreating(true);

    const { uid, displayName, photoURL, bio, education, modules } =
      auth.currentUser;
    const sub_id = v4();

    const subjectName = nameRef.current.value;
    const is_published = false;
    const is_reviewed = false;
    const re_edit = false;

    const querySnapshot = await subjectsRef
      .where("name", "==", subjectName)
      .get();

    if (!querySnapshot.empty) {
      alert("name already exists , try another name");
      setIsCreating(false);
      return;
    }

    const newSubject = {
      is_published,
      is_reviewed,
      re_edit,
      students: [],
      reviews: [],
      review: "",
      name: subjectName,
      description: descriptionRef.current.value,
      price: priceRef.current.value,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      uid,
      sub_id,
      learningOutcomes: [],
      courseStructure: [],
      messages: [],
      lessons: [],
      quizzes: [],
      challenges: [],
      displayName,
      instructor: {
        uid,
        displayName,
        photoURL,
        bio: bio || "", // Provide a default empty string if bio is undefined
        education: education || "", // Provide a default empty string if education is undefined
        modules: modules || "", // Provide a default empty string if modules is undefined they can teach
        is_instructor: true,
      },
      photoURL,
    };

    if (file) {
      const extension = file.name.split(".").pop().toLowerCase();
      const imageExtensions = ["jpg", "jpeg", "png", "gif"];

      if (imageExtensions.includes(extension)) {
        const storageRef = storage.ref();
        const fileRef = storageRef.child(file.name);
        await fileRef.put(file);
        const downloadURL = await fileRef.getDownloadURL();
        newSubject.file = {
          name: file.name,
          url: downloadURL,
        };
      } else {
        setIsCreating(false);
        alert("incorrect file type , select an image file!");

        return;
      }
    }

    await subjectsRef.add(newSubject);
    alert("subject created successfully!");
    setFormValue("");
    setFile(null);
    setIsCreating(false);
    navigate("display-user-subjects");
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
  };

  const handlePublish = async (subject_name) => {
    const subjectName = subject_name;

    const querySnapshot = await subjectsRef
      .where("name", "==", subjectName)
      .get();

    if (!querySnapshot.empty) {
      const docRef = querySnapshot.docs[0].ref;

      await docRef.update({
        is_published: true,
      });
    }
  };

  const handleReEdit = async (subject_name) => {
    const subjectName = subject_name;

    const querySnapshot = await subjectsRef
      .where("name", "==", subjectName)
      .get();

    if (!querySnapshot.empty) {
      const docRef = querySnapshot.docs[0].ref;

      await docRef.update({
        re_edit: true,
        is_reviewed: false,
        is_published: false,
        review: reviewRef.current.value,
      });
    }
  };

  const handleReview = async (subject_name) => {
    const subjectName = subject_name;

    const querySnapshot = await subjectsRef
      .where("name", "==", subjectName)
      .get();

    if (!querySnapshot.empty) {
      const docRef = querySnapshot.docs[0].ref;

      await docRef.update({
        is_reviewed: true,
      });
    }
  };

  const addReviewAndRating = async (subject_name, review, rating, user) => {
    const subjectName = subject_name;

    const querySnapshot = await subjectsRef
      .where("name", "==", subjectName)
      .get();

    if (!querySnapshot.empty) {
      const docRef = querySnapshot.docs[0].ref;
      const subjectData = querySnapshot.docs[0].data();

      // Check if the user has already sent a review for this subject
      const hasUserReviewed = subjectData.reviews.some((r) => r.user === user);

      if (hasUserReviewed) {
        alert("You have already sent a review for this subject.");
        return;
      }

      await docRef.update({
        reviews: firebase.firestore.FieldValue.arrayUnion({
          review,
          rating,
          user,
        }),
      });

      alert("Review and rating added successfully!");
    }
  };

  const addStudentToSubject = async (subject_name, student) => {
    const querySnapshot = await subjectsRef
      .where("name", "==", subject_name)
      .get();

    if (!querySnapshot.empty) {
      const docRef = querySnapshot.docs[0].ref;

      await docRef.update({
        students: firebase.firestore.FieldValue.arrayUnion(student),
      });
    }
    alert("successfully enrolled to subject!");
  };

  // Inside your SubjectsProvider component

  const deleteSubject = async (subject_name) => {
    const subjectName = subject_name;

    const querySnapshot = await subjectsRef
      .where("name", "==", subjectName)
      .get();

    if (!querySnapshot.empty) {
      const docRef = querySnapshot.docs[0].ref;

      if (window.confirm("Are you sure you want to delete this subject?")) {
        // Step 1: Delete the subject document itself
        await docRef.delete();

        // Step 2: Delete all collections and documents within the subject
        const subCollections = await docRef.listCollections();
        subCollections.forEach(async (collection) => {
          const collectionDocs = await collection.listDocuments();
          collectionDocs.forEach(async (doc) => {
            await doc.delete();
          });
          await collection.delete();
        });

        // Optional: You might want to alert a message indicating successful deletion
        alert("Subject deleted!");
      }
    }
  };

  const addContentToSubject = async (
    learningOutcomes,
    courseStructure,
    subjectId
  ) => {
    const querySnapshot = await subjectsRef
      .where("name", "==", subjectId)
      .get();

    if (!querySnapshot.empty) {
      const docRef = querySnapshot.docs[0].ref;

      // Assuming learningOutcomes and courseStructure are arrays
      const updatedLearningOutcomes = [...learningOutcomes]; // Flatten the array if needed
      const updatedCourseStructure = [...courseStructure]; // Flatten the array if needed

      await docRef.update({
        learningOutcomes: firebase.firestore.FieldValue.arrayUnion(
          ...updatedLearningOutcomes
        ),
        courseStructure: firebase.firestore.FieldValue.arrayUnion(
          ...updatedCourseStructure
        ),
      });
    }
    alert("Learning outcomes  added successfully!");
  };

  const addLessonToSubject = async (lesson, subjectId, lessonAttributes) => {
    const querySnapshot = await subjectsRef
      .where("name", "==", subjectId)
      .get();

    if (!querySnapshot.empty) {
      const docRef = querySnapshot.docs[0].ref;

      const storageRef = storage.ref(); // Use your Firebase Storage reference

      const fileRef1 = lessonAttributes.file1
        ? storageRef.child(lessonAttributes.file1.name)
        : null;
      const fileRef2 = lessonAttributes.file2
        ? storageRef.child(lessonAttributes.file2.name)
        : null;
      const fileRef3 = lessonAttributes.file3
        ? storageRef.child(lessonAttributes.file3.name)
        : null;
      const fileRef4 = lessonAttributes.file4
        ? storageRef.child(lessonAttributes.file4.name)
        : null;
      const fileRef5 = lessonAttributes.file5
        ? storageRef.child(lessonAttributes.file5.name)
        : null;

      try {
        // Upload the file to Firebase Storage
        if (fileRef1) {
          await fileRef1.put(lessonAttributes.file1);
        }
        if (fileRef2) {
          await fileRef2.put(lessonAttributes.file2);
        }
        if (fileRef3) {
          await fileRef3.put(lessonAttributes.file3);
        }
        if (fileRef4) {
          await fileRef4.put(lessonAttributes.file4);
        }
        if (fileRef5) {
          await fileRef5.put(lessonAttributes.file5);
        }

        // Get the download URL for the uploaded file
        const fileDownloadURL1 = fileRef1
          ? await fileRef1.getDownloadURL()
          : null;
        const fileDownloadURL2 = fileRef2
          ? await fileRef2.getDownloadURL()
          : null;
        const fileDownloadURL3 = fileRef3
          ? await fileRef3.getDownloadURL()
          : null;
        const fileDownloadURL4 = fileRef4
          ? await fileRef4.getDownloadURL()
          : null;
        const fileDownloadURL5 = fileRef5
          ? await fileRef5.getDownloadURL()
          : null;
        const topic = lessonAttributes.topic;
        const description = lessonAttributes.description;
        const summary = lessonAttributes.summary;
        const file_name1 = lessonAttributes.file1
          ? lessonAttributes.file1.name
          : null;
        const file_name2 = lessonAttributes.file2
          ? lessonAttributes.file2.name
          : null;
        const file_name3 = lessonAttributes.file3
          ? lessonAttributes.file3.name
          : null;
        const file_name4 = lessonAttributes.file4
          ? lessonAttributes.file4.name
          : null;
        const file_name5 = lessonAttributes.file5
          ? lessonAttributes.file5.name
          : null;

        const lesson_id = v4();
        // Assuming lesson is an array of lesson names, you can add attributes to each lesson
        const lessonsWithAttributes = lesson.map((lessonName) => ({
          students: [],
          name: "",
          topic, // Include additional attributes like topic, description, summary, and file URL
          description,
          summary,
          fileUrl1: fileDownloadURL1, // Add the file URL to the lesson attributes
          fileUrl2: fileDownloadURL2, // Add the file URL to the lesson attributes
          fileUrl3: fileDownloadURL3, // Add the file URL to the lesson attributes
          fileUrl4: fileDownloadURL4, // Add the file URL to the lesson attributes
          fileUrl5: fileDownloadURL5, // Add the file URL to the lesson attributes
          file_name1,
          file_name2,
          file_name3,
          file_name4,
          file_name5,
          lesson_id,
          subject_id: subjectId,
        }));

        await docRef.update({
          lessons: firebase.firestore.FieldValue.arrayUnion(
            ...lessonsWithAttributes
          ),
        });

       // alert("Class Created successfully!");
      } catch (error) {
        alert("Error uploading lesson file:", error);
      } 
    }
  };

  const addStudentToLesson = async (e, subject_name, lesson_id, student) => {
    e.preventDefault();
    const subjectName = subject_name;

    const querySnapshot = await subjectsRef
      .where("name", "==", subjectName)
      .get();

    if (!querySnapshot.empty) {
      const docRef = querySnapshot.docs[0].ref;
      const subjectData = querySnapshot.docs[0].data();

      // Find the lesson within the subject's lessons array based on its lesson_id
      const lessonIndex = subjectData.lessons.findIndex(
        (lesson) => lesson.lesson_id === lesson_id
      );

      if (lessonIndex === -1) {
        alert("not found.");
        return;
      }

      const lessonToUpdate = subjectData.lessons[lessonIndex];

      // Ensure that the student and lessonToUpdate are defined
      if (!lessonToUpdate || !student) {
        alert("Invalid student or lesson data.");
        return;
      }

      // Check if the student is already enrolled in the lesson
      if (lessonToUpdate.students.includes(student)) {
        
        return;
      }

      // Add the student to the lesson's students array
      lessonToUpdate.students.push(student);

      // Update the lesson within the subject's lessons array
      subjectData.lessons[lessonIndex] = lessonToUpdate;

      // Update the subject document with the modified lesson
      await docRef.update({
        lessons: subjectData.lessons,
      });

      
    } else {
      
    }
  };

  const addMessagesToSubject = async (newMessage, subjectId) => {
    const querySnapshot = await subjectsRef
      .where("name", "==", subjectId)
      .get();

    if (!querySnapshot.empty) {
      const docRef = querySnapshot.docs[0].ref;
      const timestamp = firebase.firestore.Timestamp.now();
      const id = v4();
      const sentMessage = {
        id,
        text: newMessage.text,
        name: "", // You might want to populate this with an actual value.
        displayName: newMessage.displayName,
        photoURL: newMessage.photoURL,
        messageId: v4(),
        timestamp,
        responses: [],
        fileName: newMessage.file ? newMessage.file.name || "" : "",
        fileUrl: newMessage.file ? newMessage.file.url || "" : "",
      };

      await docRef.update({
        messages: firebase.firestore.FieldValue.arrayUnion(sentMessage),
      });
      alert("Sent");
    } else {
      console.log("No documents found for subjectId:");
    }
  };

  const deleteMessageFromSubject = async (e, subject_name, messageId) => {
    e.preventDefault();
    const subjectName = subject_name;

    const querySnapshot = await subjectsRef
      .where("name", "==", subjectName)
      .get();

    if (!querySnapshot.empty) {
      const docRef = querySnapshot.docs[0].ref;
      const subjectData = querySnapshot.docs[0].data();

      // Find the message within the subject's messages array based on its messageId
      const messageIndex = subjectData.messages.findIndex(
        (message) => message.messageId === messageId
      );

      if (messageIndex === -1) {
        alert("Message not found.");
        return;
      }

      // Remove the message from the messages array
      subjectData.messages.splice(messageIndex, 1);

      // Update the subject document with the modified messages array
      await docRef.update({
        messages: subjectData.messages,
      });
      alert("Deleted");
     
    } else {
      alert("Subject not found.");
    }
  };

  const deleteLesson = async (e, subject_name, lesson_id) => {
    e.preventDefault();

    // Ask for user confirmation before proceeding with deletion
  const confirmDelete = window.confirm("Are you sure you want to delete this class?");
  if (!confirmDelete) {
    return; // Exit if the user cancels
  }



    const subjectName = subject_name;

    const querySnapshot = await subjectsRef
      .where("sub_id", "==", subjectName)
      .get();

    if (!querySnapshot.empty) {
      const docRef = querySnapshot.docs[0].ref;
      const subjectData = querySnapshot.docs[0].data();

      // Find the lesson within the subject's lessons array based on its lesson_id
      const lessonIndex = subjectData.lessons.findIndex(
        (lesson) => lesson.lesson_id === lesson_id
      );

      if (lessonIndex === -1) {
        alert("Lesson not found.");
        return;
      }

      // Remove the lesson from the lessons array
      subjectData.lessons.splice(lessonIndex, 1);

      // Update the subject document with the modified lessons array
      await docRef.update({
        lessons: subjectData.lessons,
      });

      alert("deleted!");
    } else {
      alert("Subject not found.");
    }
  };

  const addQuizToSubject = async (e, subject_name, lesson_id, quizData) => {
    e.preventDefault();
    const { uid, displayName, photoURL, bio, education, modules } =
      auth.currentUser;
    const subjectName = subject_name;

    const querySnapshot = await subjectsRef
      .where("name", "==", subjectName)
      .get();

    if (!querySnapshot.empty) {
      const docRef = querySnapshot.docs[0].ref;

      const quizId = v4();

      const newQuiz = {
        grade: 0,
        subject: subjectName,
        topic: lesson_id,
        displayName,
        question: quizData.question,
        methods: quizData.methods,
        answers: quizData.answers,
        quizId,
      };

      await docRef.update({
        quizzes: firebase.firestore.FieldValue.arrayUnion(newQuiz),
      });

      alert("Quiz added successfully!");
    } else {
      alert("Subject not found.");
    }
  };

  const addChallengeToSubject = async (lesson, subjectId, lessonAttributes) => {
    const querySnapshot = await subjectsRef
      .where("name", "==", subjectId)
      .get();

    if (!querySnapshot.empty) {
      const docRef = querySnapshot.docs[0].ref;

      const storageRef = storage.ref(); // Use your Firebase Storage reference

      const fileRef1 = lessonAttributes.file1
        ? storageRef.child(lessonAttributes.file1.name)
        : null;

      try {
        // Upload the file to Firebase Storage
        if (fileRef1) {
          await fileRef1.put(lessonAttributes.file1);
        }

        // Get the download URL for the uploaded file
        const fileDownloadURL1 = fileRef1
          ? await fileRef1.getDownloadURL()
          : null;

        const topic = lessonAttributes.topic;
        const description = lessonAttributes.description;
        const summary = lessonAttributes.summary;
        const file_name1 = lessonAttributes.file1
          ? lessonAttributes.file1.name
          : null;

        const lesson_id = v4();
        const { uid, displayName, photoURL, bio, education, modules } =
          auth.currentUser;
        // Assuming lesson is an array of lesson names, you can add attributes to each lesson
        const lessonsWithAttributes = lesson.map((lessonName) => ({
          responses: [],
          name: displayName,
          topic, // Include additional attributes like topic, description, summary, and file URL
          description,
          summary,
          fileUrl1: fileDownloadURL1, // Add the file URL to the lesson attributes
          file_name1,
          lesson_id,
          subject_id: subjectId,
        }));

        await docRef.update({
          challenges: firebase.firestore.FieldValue.arrayUnion(
            ...lessonsWithAttributes
          ),
        });

        alert("Question sent!");
      } catch (error) {
        alert("Error uploading lesson file:", error);
      }
    }
  };

  const addResponseToChallenge = async (
    subjectName,
    challengeId,
    responseAttributes
  ) => {
    const querySnapshot = await subjectsRef
      .where("name", "==", subjectName)
      .get();

    if (!querySnapshot.empty) {
      const docRef = querySnapshot.docs[0].ref;

      try {
        

        const { summary } = responseAttributes;
        const responseId = v4();
        const { uid, displayName, photoURL, bio, education, modules } =
          auth.currentUser;
        const newResponse = {
          displayName,
          reviews: [],
          summary,
          responseId,
          
        };

        const subjectData = querySnapshot.docs[0].data();
        const challengeIndex = subjectData.challenges.findIndex(
          (challenge) => challenge.lesson_id === challengeId
        );

        if (challengeIndex !== -1) {
          // Update the challenge with the new response
          if (!subjectData.challenges[challengeIndex].responses) {
            subjectData.challenges[challengeIndex].responses = []; // Ensure responses array exists
          }
          subjectData.challenges[challengeIndex].responses.push(newResponse);

          await docRef.update({
            challenges: subjectData.challenges,
          });

          alert("Answer sent successfully!");
        } else {
          alert("question not found.");
        }
      } catch (error) {
        alert("Error uploading response file:", error);
        console.log("error",error)
      }
    } else {
      alert("Subject not found.");
    }
  };

  const removeResponseFromChallenge = async (e,subjectName, challengeId, responseId) => {
    e.preventDefault();
     if(window.confirm("are you sure?")){
    const querySnapshot = await subjectsRef
      .where("name", "==", subjectName)
      .get();
  
    if (!querySnapshot.empty) {
      const docRef = querySnapshot.docs[0].ref;
      const subjectData = querySnapshot.docs[0].data();
  
      // Find the challenge within the subject's challenges array based on its challengeId
      const challengeIndex = subjectData.challenges.findIndex(
        (challenge) => challenge.lesson_id === challengeId
      );
  
      if (challengeIndex === -1) {
        alert(" not found.");
        return;
      }
  
      const challenge = subjectData.challenges[challengeIndex];
  
      // Find the response within the challenge's responses array based on its responseId
      const responseIndex = challenge.responses.findIndex(
        (response) => response.responseId === responseId
      );
  
      if (responseIndex === -1) {
        alert("Response not found.");
        return;
      }
  
      // Remove the response from the responses array
      challenge.responses.splice(responseIndex, 1);
  
      // Update the subject document with the modified challenges array
      await docRef.update({
        challenges: subjectData.challenges,
      });
  
      alert("deleted!");
    } else {
      alert("Subject not found.");
    }
  }
  };
  

  const addReviewToResponse = async (
    subjectName,
    challengeId,
    responseId,
    score,
    summary
  ) => {
    const querySnapshot = await subjectsRef
      .where("name", "==", subjectName)
      .get();

    if (!querySnapshot.empty) {
      const docRef = querySnapshot.docs[0].ref;

      const { uid, displayName, photoURL, bio, education, modules } =
        auth.currentUser;
      const newReview = {
        displayName,
        score,
        summary,
      };

      const subjectData = querySnapshot.docs[0].data();
      const challengeIndex = subjectData.challenges.findIndex(
        (challenge) => challenge.lesson_id === challengeId
      );

      if (challengeIndex !== -1) {
        const challenge = subjectData.challenges[challengeIndex];
        const responseIndex = challenge.responses.findIndex(
          (response) => response.responseId === responseId
        );

        if (responseIndex !== -1) {
          if (!challenge.responses[responseIndex].reviews) {
            challenge.responses[responseIndex].reviews = []; // Ensure reviews array exists
          }
          challenge.responses[responseIndex].reviews.push(newReview);

          await docRef.update({
            challenges: subjectData.challenges,
          });

          alert("Review added successfully!");
        } else {
          alert("Response not found.");
        }
      } else {
        alert("question not found.");
      }
    } else {
      alert("Subject not found.");
    }
  };

  const addResponseToMessage = async (subjectName, messageId, response) => {
    console.log(
      "subject name",
      subjectName,
      "message id",
      messageId,
      "response",
      response
    );
    const querySnapshot = await subjectsRef
      .where("name", "==", subjectName)
      .get();

    if (!querySnapshot.empty) {
      const docRef = querySnapshot.docs[0].ref;

      const subjectData = querySnapshot.docs[0].data();
      const messageIndex = subjectData.messages.findIndex(
        (message) => message.id === messageId
      );

      if (messageIndex !== -1) {
        // Ensure responses array exists for the message
        if (!subjectData.messages[messageIndex].responses) {
          subjectData.messages[messageIndex].responses = [];
        }

        // Push the new response to the message's responses array
        subjectData.messages[messageIndex].responses.push(response);

        // Update the subject document with the modified messages array
        await docRef.update({
          messages: subjectData.messages,
        });
        alert("Sent");
      
      } else {
        alert("Message not found.");
      }
    } else {
      alert("Subject not found.");
    }
  };

  const deleteResponseFromMessage = async (
    e,
    subjectName,
    messageId,
    responseId
  ) => {
    e.preventDefault();

    console.log(
      "subject name",
      subjectName,
      "message id",
      messageId,
      "response",
      responseId
    );
    const querySnapshot = await subjectsRef
      .where("name", "==", subjectName)
      .get();

    if (!querySnapshot.empty) {
      const docRef = querySnapshot.docs[0].ref;
      const subjectData = querySnapshot.docs[0].data();

      // Find the message within the subject's messages array based on its messageId
      const messageIndex = subjectData.messages.findIndex(
        (message) => message.id === messageId
      );

      if (messageIndex !== -1) {
        const message = subjectData.messages[messageIndex];

        // Find the response within the message's responses array based on its responseId
        const responseIndex = message.responses.findIndex(
          (response) => response.id === responseId
        );

        if (responseIndex !== -1) {
          // Remove the response from the responses array
          message.responses.splice(responseIndex, 1);

          // Update the subject document with the modified messages array
          await docRef.update({
            messages: subjectData.messages,
          });

          alert("Deleted");
        } else {
          alert("Response not found.");
        }
      } else {
        alert("Message not found.");
      }
    } else {
      alert("Subject not found.");
    }
  };
   
  const deleteQuizFromSubject = async (e, subject_name, quiz_id) => {
    e.preventDefault();

    if(window.confirm("are you sure?")){
    const subjectName = subject_name;

  
    const querySnapshot = await subjectsRef
      .where("name", "==", subjectName)
      .get();
  
    if (!querySnapshot.empty) {
      const docRef = querySnapshot.docs[0].ref;
      const subjectData = querySnapshot.docs[0].data();
  
      // Find the quiz within the subject's quizzes array based on its quiz_id
      const quizIndex = subjectData.quizzes.findIndex(
        (quiz) => quiz.quizId === quiz_id
      );
  
      if (quizIndex === -1) {
        alert("Quiz not found.");
        return;
      }
  
      // Remove the quiz from the quizzes array
      subjectData.quizzes.splice(quizIndex, 1);
  
      // Update the subject document with the modified quizzes array
      await docRef.update({
        quizzes: subjectData.quizzes,
      });
  
      alert("Quiz deleted successfully!");
    } else {
      alert("Subject not found.");
    }
  }
  };
  
  const deleteChallengeFromSubject = async (e, subject_name, challenge_id) => {
    e.preventDefault();
    if(window.confirm("are you sure?")){
    const subjectName = subject_name;
  
    const querySnapshot = await subjectsRef
      .where("name", "==", subjectName)
      .get();
  
    if (!querySnapshot.empty) {
      const docRef = querySnapshot.docs[0].ref;
      const subjectData = querySnapshot.docs[0].data();
  
      // Find the challenge within the subject's challenges array based on its challenge_id
      const challengeIndex = subjectData.challenges.findIndex(
        (challenge) => challenge.lesson_id === challenge_id
      );
  
      if (challengeIndex === -1) {
        alert("Challenge not found.");
        return;
      }
  
      // Remove the challenge from the challenges array
      subjectData.challenges.splice(challengeIndex, 1);
  
      // Update the subject document with the modified challenges array
      await docRef.update({
        challenges: subjectData.challenges,
      });
  
      alert("deleted successfully!");
    } else {
      alert("Subject not found.");
    }
  }
  };
  
  

  const values = {
    handleFileChange,
    sendSubject,
    path,
    dummy,
    nameRef,
    reviewRef,
    descriptionRef,
    subjectsRef,
    priceRef,
    query,
    subjects,
    setFormValue,
    setFile,
    sendSubject,
    formValue,
    handlePublish,
    handleReview,
    addReviewAndRating,
    handleReEdit,
    isCreating,
    addStudentToSubject,
    addContentToSubject,
    addLessonToSubject,
    addStudentToLesson,
    addMessagesToSubject,
    deleteMessageFromSubject,
    deleteSubject,
    deleteLesson,
    addQuizToSubject,
    addChallengeToSubject,
    addResponseToChallenge,
    addReviewToResponse,
    addResponseToMessage,
    removeResponseFromChallenge,
    deleteResponseFromMessage,
    deleteQuizFromSubject,
    deleteChallengeFromSubject,
  };

  return (
    <SubjectsContext.Provider value={values}>
      {children}
    </SubjectsContext.Provider>
  );
};

export { SubjectsProvider };

export default SubjectsContext;
