import React from "react";

function HomeContent() {
  

  return (
    <div id="wrapper home-content" className="divided">
      <section className="banner style1 orient-left content-align-left image-position-right fullscreen onload-image-fade-in onload-content-fade-right">
        <div className="content">
          <h1>Purpose and Value</h1>
          <p className="major">
            At <span className="fw-bold text-success">KnowledgeShare</span>, we
            aim to create a vibrant learning community where students and
            teachers connect, collaborate, and excel together. Our platform
            fosters knowledge exchange, enabling learners to access high-quality
            course content, engage in real-time communication, and enhance their
            learning experience.
          </p>
        </div>
        <div class="image">
          <img src="images/purpose1.svg" alt="" />
        </div>
      </section>

      <section
        class="spotlight style1 orient-right content-align-left image-position-center onscroll-image-fade-in"
        id="first"
      >
        <div class="content">
          <h2>Features and Benefits</h2>
          <p>
            With <span className="fw-bold text-success">KnowledgeShare</span>,
            you can unlock a world of learning possibilities. Access a
            marketplace of diverse course content, engage in seamless
            communication through our chat feature, post questions, share
            materials, and participate in interactive quizzes. Enjoy the
            benefits of an all-in-one platform that enriches your learning
            journey.
          </p>
        </div>
        <div class="image">
          <img src="images/benefits1.svg" alt="" />
        </div>
      </section>

      <section class="spotlight style1 orient-left content-align-left image-position-center onscroll-image-fade-in">
        <div class="content">
          <h2>Community and Collaboration</h2>

          <p>
            Join our thriving community of learners where{" "}
            <span className="fw-bold text-success">KnowledgeShare</span> knows
            no boundaries. Connect with like-minded students, tap into their
            expertise, and collaborate on projects. Our platform encourages
            interaction, fosters a supportive environment, and cultivates
            valuable relationships that enhance your educational experience.
          </p>
        </div>
        <div class="image">
          <img src="images/community2.svg" alt="" />
        </div>
      </section>

      <section class="spotlight style1 orient-right content-align-left image-position-center onscroll-image-fade-in">
        <div class="content">
          <h2>Course Content Exchange</h2>
          <p>
            At <span className="fw-bold text-success">KnowledgeShare</span>,
            teachers can easily upload and sell their comprehensive course
            materials, including lecture notes, presentations, assignments, and
            study guides. Our diverse range of subjects and topics ensures that
            students have access to a wide array of learning options tailored to
            their needs.
          </p>
        </div>
        <div class="image">
          <img src="images/community1.svg" alt="" />
        </div>
      </section>

      <section class="wrapper style1 align-center">
        <div class="inner">
          <h2>Security and Support</h2>
          <p>
            Your security and privacy are our top priorities. Rest assured that
             <span className="fw-bold text-success"> KnowledgeShare</span> employs
            stringent measures to protect your personal information and ensures
            secure transactions. Additionally, our dedicated customer support
            team is available to provide timely assistance, ensuring a smooth
            and hassle-free experience for all users.

          </p>
         
        </div>
      </section>

      <section className="wrapper style1 align-center">
        <div className="inner medium">
          <h2>Get in touch</h2>

          <form  action="https://formsubmit.co/36e23f0d42f52f6b1c641ce3396f0125" method="POST">
          <input type="hidden" name="_subject" value="KnowledgeShare submission" />
          <input type="hidden" name="_cc" value="mphashiaustin@email.com"></input>
          <input type="hidden" name="_autoresponse" value="KnowledgeShare  : 'your email has been recieved!' "></input>
            <div class="fields">
              <div class="field half">
                <label for="name">Name</label>
                <input  type="text" name="name" id="name" />
              </div>
              <div class="field half">
                <label  for="email">Email</label>
                <input type="email" name="email" id="email" />
              </div>
              <div class="field">
                <label  for="message">Message</label>
                <textarea name="message" id="message" rows="6"></textarea>
              </div>
            </div>
            <ul  class="actions special">
              <li>
                <input
               
                  type="submit"
                  name="submit"
                  id="submit"
                  value="Send Message"
                />
              </li>
            </ul>
          </form>
        </div>
      </section>
    </div>
  );
}

export default HomeContent;
