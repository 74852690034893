import React from "react";
import Signup from "./Signup";
import { AuthProvider } from "../contexts/AuthContext";
import { Routes, Route } from "react-router-dom";

import CreateSubjects from "../Pages/CreateSubjects";
import DisplaySubjects from "../Pages/DisplaySubjects";
import CreateCommunity from "../Pages/CreateCommunity";
import DisplayCommunities from "../Pages/DisplayCommunities";
import DisplayUserSubjects from "../Pages/DisplayUserSubjects";
import Home from "../Pages/Home";
import Login from "./Login";
import PrivateRoute from "./PrivateRoute";
import Navigation from "../Routing/Navigation";
import ForgotPassword from "./ForgotPassword";
import Dashboard from "./Dashboard";
import Subject from "../Pages/Subject";
import Community from "../Pages/Community";
import Lesson from "../Pages/Lesson";

import UserSubject from "../Pages/UserSubject";
import UserLesson from "../Pages/UserLesson";
import UpdateProfile from "./UpdateProfile";
import ReviewSubjects from "../Pages/ReviewSubjects";
import OpenTriviaQuiz from "../Pages/OpenTriviaQuiz";
import QuizComponent from "../Pages/QuizComponent";

import Instructors from "../Pages/Instructors";
import Instructor from "../Pages/Instructor";

import InstructorSignup from "./InstructorSignup";

import CreateGroup from "../Pages/CreateGroup";
import QuizScores from "../Pages/QuizScores";
import DisplayUserCommunities from "../Pages/DisplayUserCommunities";

import UploadMaterials from "../Pages/UploadMaterials";
import DisplayMaterials from "../Pages/DisplayMaterials";
import AllUsersQuizScores from "../Pages/AllUsersQuizScores";
import Leaderboards from "../Pages/Leaderboards";
import Users from "../Pages/Users";

function Authentication() {
  return (
    <div className="main-grid">
      <div>
      <Navigation />
      <div>
        <AuthProvider>
          <Routes>
            <Route element={<PrivateRoute />}>
              <Route element={<CreateSubjects />} path="/create-subjects" />
              <Route element={<CreateCommunity />} path="/create-community" />
              <Route element={<CreateGroup />} path="/create-groups" />
              <Route element={<QuizScores />} path="/quiz-scores" />
              <Route element={<AllUsersQuizScores />} path="/all-quiz-scores" />
              <Route element={<Users />} path="/users" />
              <Route element={<Leaderboards />} path="/leaderboards" />
              <Route
                element={<DisplayUserSubjects />}
                path="/display-user-subjects"
              />
              <Route
                element={<DisplayUserCommunities />}
                path="/display-user-communities"
              />
              <Route element={<DisplaySubjects />} path="/display-subjects" />
              <Route
                element={<DisplayCommunities />}
                path="/display-communities"
              />
              <Route element={<ReviewSubjects />} path="/review-subjects" />
              {/**  posts commented
              <Route element={<CreateGroup />} path="/create-group" />
              */}
              <Route
                element={<UserSubject />}
                path="/display-user-subjects/:subject"
              />
              <Route
                element={<Community />}
                path="/display-communities/:subject"
              />
              <Route element={<Subject />} path="/display-subjects/:subject" />
              <Route element={<Subject />} path="/review-subjects/:subject" />
              <Route
                element={<Lesson />}
                path="/display-subjects/:subject/:lesson"
              />
              <Route
                element={<Lesson />}
                path="/review-subjects/:subject/:lesson"
              />
              <Route
                element={<UserLesson />}
                path="/display-user-subjects/:subject/:lesson"
              />

              <Route element={<Dashboard />} path="/profile" />
              <Route element={<UpdateProfile />} path="/update-profile" />
              <Route element={<QuizComponent />} path="/quiz-api" />
              <Route element={<UploadMaterials />} path="/upload" />
              <Route element={<DisplayMaterials />} path="/display" />
              <Route element={<Instructors />} path="/instructors" />
            <Route
              element={<Instructor />}
              path="/instructors/:instructorUid"
            />
            </Route>
            <Route element={<Home />} path="/" />
            <Route element={<OpenTriviaQuiz />} path="/practice-quiz" />
           
            <Route path="/signup" element={<Signup />} />
            <Route path="/instructor-signup" element={<InstructorSignup />} />
            <Route path="/login" element={<Login />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
          </Routes>
        </AuthProvider>
      </div>
      </div>
      <footer class="mt-5 wrapper style1 bg-dark text-success align-center">
        <h4 className="m-0 mt-5 py-4 text-center text-success">
          Austin Mphashi
        </h4>
        <div class="inner">
          <ul class="icons">
            <li>
              <a href="#" class="icon text-success brands style2 fa-twitter">
                <span class="label">Twitter</span>
              </a>
            </li>
            <li>
              <a href="#" class="icon text-success brands style2 fa-facebook-f">
                <span class="label">Facebook</span>
              </a>
            </li>
            <li>
              <a href="#" class="icon text-success brands style2 fa-instagram">
                <span class="label">Instagram</span>
              </a>
            </li>
            <li>
              <a
                href="#"
                class="icon text-success brands style2 fa-linkedin-in"
              >
                <span class="label">LinkedIn</span>
              </a>
            </li>
            <li>
              <a href="#" class="icon text-success style2 fa-envelope">
                <span class="label">Email</span>
              </a>
            </li>
          </ul>
        </div>
        <hr/>
        
      </footer>
    </div>
  );
}

export default Authentication;
