import React, { useRef, useState } from "react";
import { Form, Button, Card, Alert } from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import firebase from "firebase/compat/app";

export default function Login() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const { login } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  async function handleSubmit(e) {
    e.preventDefault();
    
    try {
      setError("");
      setLoading(true);
  
      const email = emailRef.current.value;
      const password = passwordRef.current.value;
  
      // Retrieve user data from Firestore
      const userDoc = await firebase.firestore().collection("usersCollection").doc("usersDoc").get();
      if (userDoc.exists) {
        const usersData = userDoc.data().users;
        const userData = usersData.find(user => user.email === email);
        if (userData) {
          // Attempt to log in
          await login(email, password);
          // Navigate to the home page after successful login
          navigate("/");
          return;
        } else if (userData && userData.disabled) {
          throw new Error("Your account has been disabled.");
        }
      }
      throw new Error("User not found or disabled.");
    } catch (error) {
      setError(error.message || "Failed to log in");
    }
  
    setLoading(false);
  }
  

  return (
    <div className="container xs-container-fluid m-auto mt-5 mb-5">
      <section className="mb-5 mt-5">
        <div className="container pt-5 my-5">
          <div className="row d-flex align-items-center justify-content-center">
            <div className="col-md-8 col-lg-7 col-xl-6">
              <img src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.svg"
                className="img-fluid" alt="Phone image" />
            </div>
           
            <div className="col-md-7 col-lg-5 col-xl-5 offset-xl-1">
              {error && <Alert variant="danger">{error}</Alert>}
              <form onSubmit={handleSubmit}>
                <div className="form-outline mb-4">
                  <input type="email" id="form1Example13" className="form-control form-control-lg" ref={emailRef} required />
                  <label className="form-label" htmlFor="form1Example13">Email address</label>
                </div>

                <div className="form-outline mb-4">
                  <input type="password" id="form1Example23" className="form-control form-control-lg" ref={passwordRef} required />
                  <label className="form-label" htmlFor="form1Example23">Password</label>
                </div>

                <div className="d-flex justify-content-around align-items-center mb-4">
                  <a href="#!"><Link to="/forgot-password">Forgot Password?</Link></a>
                  <span> Need an account? <span className="text-warning"><Link to="/signup">Register</Link></span></span>
                </div>

                <button className="btn btn-primary w-75 text-center" type="submit" disabled={loading}>
                  {loading ? "Loading..." : "Login"}
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
