import React, { useState, useContext, useRef , useEffect} from "react";
import { Modal } from "react-bootstrap";
import Message from "./Message";
import { auth } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import CommunityContext from "./Contexts/CommunityContext";
import { useLocation } from "react-router-dom";
import CreateQuizQuestion from "./CreateQuizQuestion";
import QuizScores from "./QuizScores";
import CreateChallenges from "./CreateChallenges";
import Challenges from "./Challenges";
import ReviewForm from "./ReviewForm";
import Quizzes from "./Quizzes";

function Community() {
  const contentDisplayRef = useRef(null);
  const values = useContext(CommunityContext);
  const [user] = useAuthState(auth);
  const [chat, setChat] = useState(false);
  const [quiz, setQuiz] = useState(false);
  const [quizScores, setQuizScores] = useState(false);
  const [createQuiz, setCreateQuiz] = useState(false);
  const [challenge, setChallenge] = useState(false);
  const [createChallenge, setCreateChallenge] = useState(false);
  const [close, setClose] = useState(false);
  const location = useLocation();
  const { data } = location.state;

  const [showCreateQuizModal, setShowCreateQuizModal] = useState(false);
  const [showCreateChallengeModal, setShowCreateChallengesModal] =
    useState(false);

    useEffect(() => {
      window.scrollTo(0, 0); // Scroll to the top when component mounts
    }, []);

  const handleCloseModal = () => {
    setShowCreateQuizModal(false);
    setShowCreateChallengesModal(false);
  };

  // Update scrollToContentDisplay function
  const scrollToContentDisplay = () => {
    if (contentDisplayRef.current) {
      contentDisplayRef.current.scrollIntoView({
        behavior: "smooth", // or "smooth" if you prefer smooth scrolling
        block: "start",
      });
    }
  };

  const handleClose = (e) => {
    e.preventDefault();
    setQuiz(false);
    setChallenge(false);
    setCreateQuiz(false);
    setChat(false);
    setQuizScores(false);
    setCreateChallenge(false);
    scrollToContentDisplay();
  };

  const handleQuiz = (e) => {
    e.preventDefault();
    setQuiz(true);
    setChallenge(false);
    setCreateQuiz(false);
    setChat(false);
    setQuizScores(false);
    setCreateChallenge(false);
    scrollToContentDisplay();
  };
  const handleChallenge = (e) => {
    e.preventDefault();
    setChallenge(true);
    setQuiz(false);
    setCreateQuiz(false);
    setChat(false);
    setQuizScores(false);
    setCreateChallenge(false);
    scrollToContentDisplay();
  };
  const handleCreateChallenge = (e) => {
    e.preventDefault();
    setCreateChallenge(true);
    setChallenge(false);
    setQuiz(false);
    setCreateQuiz(false);
    setChat(false);
    setQuizScores(false);
    scrollToContentDisplay();
    setShowCreateChallengesModal(true);
  };
  const handleQuizScores = (e) => {
    e.preventDefault();
    setQuizScores(true);
    setChallenge(false);
    setQuiz(false);
    setCreateQuiz(false);
    setChat(false);
    setCreateChallenge(false);
    scrollToContentDisplay();
  };

  const handleCreateQuiz = (e) => {
    e.preventDefault();
    setCreateQuiz(true);
    setChallenge(false);
    setQuiz(false);
    setChat(false);
    setQuizScores(false);
    setCreateChallenge(false);
    scrollToContentDisplay();
    setShowCreateQuizModal(true);
  };

  const handleChat = (e) => {
    e.preventDefault();
    setChat(true);
    setChallenge(false);
    setQuiz(false);
    setCreateQuiz(false);
    setQuizScores(false);
    setCreateChallenge(false);
    scrollToContentDisplay();
  };
  
  

  return (
    <div className="container mt-5">
      <h6>Display Content</h6>

      {!data.students?.includes(user.displayName) &&
      user.displayName !== data.displayName ? (
        <div>
          {data.courseStructure
            ?.map((student) => student)
            .includes(user?.displayName) &&
          user.displayName !== data?.displayName ? (
            <span className="badge badge-waiting">requested to join...</span>
          ) : (
            <div>
              <p class="text-info my-2">
                Join community to access Quiz and Chats
              </p>
              <p
                onClick={(e) =>
                  values.addStudentToSubject(e, data?.name, user.displayName)
                }
                className="btn btn-success"
              >
                Click To Join Group
              </p>
            </div>
          )}
        </div>
      ) : (
        <div></div>
      )}

      <div className="mt-5 mb-5">
        <div className="row g-0">
          <div className="col-lg-3 col-md-12">
            <div className="content-grid" id="sidebar-wrapper">
              <div className="border-end bg-white">
                <div className="list-group list-group-flush">
                  <a className="list-group-item list-group-item-action list-group-item-light p-3">
                    Select Content
                  </a>

                  <a
                    onClick={handleChat}
                    className={`list-group-item list-group-item-action list-group-item-light p-3 ${
                      !data.students?.includes(user.displayName) &&
                      data.displayName !== user.displayName
                        ? "disabled"
                        : ""
                    }`}
                    href="#!"
                  >
                    <span className="text-info fw-bolder">Chat</span>
                  </a>
                  <a
                    onClick={handleQuiz}
                    className={`list-group-item list-group-item-action list-group-item-light p-3 ${
                      !data.students?.includes(user.displayName) &&
                      data.displayName !== user.displayName
                        ? "disabled"
                        : ""
                    }`}
                    href="#!"
                  >
                    <span className="text-info fw-bolder">Quiz</span>
                  </a>
                  <a
                    onClick={handleChallenge}
                    className={`list-group-item list-group-item-action list-group-item-light p-3 ${
                      !data.students?.includes(user.displayName) &&
                      data.displayName !== user.displayName
                        ? "disabled"
                        : ""
                    }`}
                    href="#!"
                  >
                    <span className="text-info fw-bolder">
                      Notes & Questions
                    </span>
                  </a>
                  <a
                    onClick={handleQuizScores}
                    className={`list-group-item list-group-item-action list-group-item-light p-3 ${
                      !data.students?.includes(user.displayName) &&
                      data.displayName !== user.displayName
                        ? "disabled"
                        : ""
                    }`}
                    href="#!"
                  >
                    <span className="text-info fw-bolder">Quiz Scores</span>
                  </a>
                  {/**/}
                  <a
                    onClick={handleCreateQuiz}
                    className={`list-group-item list-group-item-action list-group-item-light p-3 ${
                      !data.students?.includes(user.displayName) &&
                      data.displayName !== user.displayName
                        ? "disabled"
                        : ""
                    }`}
                    href="#!"
                  >
                    <span className="text-info fw-bolder">Create Quiz</span>
                  </a>

                  <a
                    onClick={handleCreateChallenge}
                    className={`list-group-item list-group-item-action list-group-item-light p-3 ${
                      !data.students?.includes(user.displayName) &&
                      data.displayName !== user.displayName
                        ? "disabled"
                        : ""
                    }`}
                    href="#!"
                  >
                    <span className="text-info fw-bolder">
                      Send Notes / Questions
                    </span>
                  </a>
                  <a
                    onClick={handleClose}
                    className={`list-group-item list-group-item-action list-group-item-light p-3 ${
                      !data.students?.includes(user.displayName) &&
                      data.displayName !== user.displayName
                        ? ""
                        : ""
                    }`}
                    href="#!"
                  >
                    <span className="text-info fw-bolder">Group Menu</span>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-9 col-md-12" ref={contentDisplayRef}>
            <div id="page-content-wrapper">
              <nav className="navbar navbar-expand-lg navbar-light bg-light border-bottom">
                <div className="container-fluid">
                  <h6 className="">Content display</h6>
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupported"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="navbar-toggler-icon"></span>
                  </button>
                  <div
                    className="collapse navbar-collapse"
                    id="navbarSupported"
                  >
                    <ul className="navbar-nav ms-auto mt-2 mt-lg-0">
                      <li className="nav-item active">
                        <a onClick={handleChat} className="nav-link" href="#!">
                          Chat
                        </a>
                      </li>
                      <li className="nav-item">
                        <a onClick={handleQuiz} className="nav-link" href="#!">
                          Quiz
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </nav>

              <div className="container-fluid">
                <section className="w-100">
                  <div className="w-100">
                    <div className="w-100 px-0 mx-0 h-100">
                      <div className="w-100">
                        {chat ? (
                          <div>
                            <Message path={data?.name} type={"community"} />
                          </div>
                        ) : quiz ? (
                          <div
                            style={{
                              height: "100%",
                              maxHeight: "500px",
                              overflowY: "auto",
                            }}
                            className="mt-4"
                          >
                            <Quizzes
                              data={data.quizzes && data.quizzes}
                              topic={data?.sub_id}
                              subject={data?.name}
                              type="community"
                            />
                          </div>
                        ) : createQuiz ? (
                          <div>
                            <Modal
                              show={showCreateQuizModal}
                              onHide={handleCloseModal}
                            >
                              <Modal.Header closeButton>
                                <Modal.Title>Create Quiz</Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                {/* Render CreateQuiz component here */}
                                <CreateQuizQuestion
                                  lesson_id={data?.sub_id}
                                  subject={data?.name}
                                  type={"community"}
                                />
                              </Modal.Body>
                            </Modal>
                          </div>
                        ) : quizScores ? (
                          <QuizScores
                            subject={data?.name}
                            topic={data?.sub_id}
                            students={data?.students}
                            grade={data?.name}
                          />
                        ) : createChallenge ? (
                          <Modal
                            show={showCreateChallengeModal}
                            onHide={handleCloseModal}
                          >
                            <Modal.Header closeButton>
                              <Modal.Title>Create Questions</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              {/* Render CreateQuiz component here */}
                              <CreateChallenges
                                subjectId={data?.name}
                                type="community"
                              />
                            </Modal.Body>
                          </Modal>
                        ) : challenge ? (
                          <Challenges
                            challenges={data?.challenges}
                            type="community"
                          />
                        ) : (
                          <div>
                            <div
                              style={{
                                maxHeight: "500px",
                                overflowY: "scroll",
                                scrollBehavior: "smooth",
                              }}
                            >
                              <div className="my-4">
                                <h5 className="my-3">Learners</h5>
                                <ul className="my-3">
                                  {data?.students?.map((std) => (
                                    <li className="my-2">
                                      {std}{" "}
                                      {data?.displayName ===
                                        user.displayName && (
                                        <button
                                          onClick={(e) =>
                                            values.removeStudentFromSubject(
                                              e,
                                              data?.name,
                                              std
                                            )
                                          }
                                          className="text-danger"
                                        >
                                          remove
                                        </button>
                                      )}
                                    </li>
                                  ))}
                                </ul>
                                <h6 className="my-2">Requests to join</h6>
                                <ul className="my-3">
                                  {data?.courseStructure?.map((std) => (
                                    <li className="my-2">
                                      {std}{" "}
                                      {data?.displayName ===
                                        user.displayName && (
                                        <button
                                          onClick={(e) =>
                                            values.approveStudentToGroup(
                                              e,
                                              data?.name,
                                              std
                                            )
                                          }
                                          className="text-success"
                                        >
                                          add to group
                                        </button>
                                      )}
                                    </li>
                                  ))}
                                </ul>
                              </div>
                              </div>
                              <div className="my-3 px-0 ">
                                <ReviewForm
                                  SubjectName={data?.name}
                                  subject={data}
                                  type={"community"}
                                />
                              </div>

                              {!data.students?.includes(user.displayName) &&
                              user.displayName !== data.displayName ? (
                                <div></div>
                              ) : (
                                <div className="my-3">
                                  {data?.displayName !== user.displayName && (
                                    <p
                                      onClick={(e) =>
                                        values.removeStudentFromSubject(
                                          e,
                                          data?.name,
                                          user.displayName
                                        )
                                      }
                                      className="btn btn-outline-danger"
                                    >
                                      Leave group
                                    </p>
                                  )}
                                </div>
                              )}

                              {data?.displayName === user.displayName && (
                                <p
                                  onClick={(e) =>
                                    values.deleteSubject(e, data?.name)
                                  }
                                  className="btn btn-outline-danger my-3"
                                >
                                  delete group
                                </p>
                              )}
                           
                          </div>
                        )}
                        <button onClick={handleClose} className="mt-3 btn ">
                          <i className="fas fa-times"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Community;
