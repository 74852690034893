import React, { useContext, useState } from "react";
import CommunityContext from "./Contexts/CommunityContext";
import SubjectsContext from "./Contexts/SubjectContext";
import { v4 } from "uuid";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import the styles


function CreateChallenges({ subjectId, type }) {

  let cont;

  if (type === "community") {
    cont = CommunityContext;
  } else {
    cont = SubjectsContext;
  }

  const values = useContext(cont);

  const [isCreating, setIsCreating] = useState(false);
  const [lesson, setLesson] = useState([]);
  const l_id = v4();
  const [file_name1, setFileName1] = useState("");

  const [lessonAttributes, setLessonAttributes] = useState({
    topic: "",
    description: "",
    l_id,
    summary: "",
    file1: null,
    file_name1,

  });

  /* const handleAddLearningOutcome = () => {
    setLearningOutcomes([...learningOutcomes, ""]);
  };/*/
  

  const handleAddLesson = () => {
    setLesson([...lesson, ""]);
  };

  /*const handleLessonAttributeChange = (e) => {
    const { name, value } = e.target;
    setLessonAttributes({ ...lessonAttributes, [name]: value });
  };*/
  const handleLessonAttributeChange = (value, attributeName) => {
    setLessonAttributes((prevAttributes) => ({
      ...prevAttributes,
      [attributeName]: value,
    }));
  };

  const handleFileChange1 = (e) => {
    const file1 = e.target.files[0];
    const fileName = file1 ? file1.name : ""; // Extract the file name
    setFileName1(fileName);
    setLessonAttributes({ ...lessonAttributes, file1, file_name1: fileName });
  };

  const handleLessonSave = async () => {
    handleAddLesson();
    if (lesson.length === 0) {
      return;
    }
     // Check if any required input is empty
  if (
    lessonAttributes.topic.trim() === "" 
  ) {
    alert("Please fill out all required fields.");
    return;
  }

    setIsCreating(true); // Set loading state to true

    try {
      // Pass lesson attributes like topic, description, summary, and file to your addLessonToSubject function.
      values.addChallengeToSubject(lesson, subjectId, lessonAttributes);
      // Clear the fields after saving
      setLesson([]);
      setLessonAttributes({
        topic: "",
        description: "",
        summary: "",
        file1: null,
      });
      // Clear the file name states
      setFileName1("");
      setIsCreating(false); // Set loading state to false after success
     
    } catch (error) {
      setIsCreating(false); // Set loading state to false if there's an error
      alert("Error adding content:", error.message);
    }
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, false] }],
      ["bold"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
      ["clean"],
    ],
  };
 
  return (
    <div className="my-2 ">
      <p className="text-info my-2 fw-bolder">1. Send questions for others to answer</p>
      <p className="text-success my-2 fw-bolder">2. Upload a file with your question (image)</p>
      {/*
      <textarea
        name="topic"
        value={lessonAttributes.topic}
        onChange={handleLessonAttributeChange}
        placeholder="Topic"
        className="m-2"
      />

       <input
        type="text"
        name="description"
        value={lessonAttributes.description}
        onChange={handleLessonAttributeChange}
        placeholder="Description"
        className="m-2"
        style={{ height: "60px" }}
      />

      <input
        type="text"
        name="summary"
        value={lessonAttributes.summary}
        onChange={handleLessonAttributeChange}
        placeholder="Summary"
        className="m-2"
        style={{ height: "60px" }}
      />
      */}

      <h6 >Write Question</h6>

      <ReactQuill
        name="topic"
        value={lessonAttributes.topic}
        onChange={(e) => handleLessonAttributeChange(e, "topic")}
        placeholder="Write Question"
        className="react-quill mt-2 mb-3"
        modules={modules}
      />

    
      <button
        className="btn btn-outline-dark text-primary my-2"
        onClick={handleLessonSave}
        disabled={isCreating}
      >
        {isCreating ? "sending..." : "Click Twice To Send"}
      </button>
    </div>
  );
}

export default CreateChallenges;
