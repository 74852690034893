import React, { useEffect, useState } from "react";
import axios from "axios";


const QuizComponent = () => {

  
  const [quizzes, setQuizzes] = useState([]);
  const [currentQuizIndex, setCurrentQuizIndex] = useState(0);
  const [score, setScore] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [selectedSubject, setSelectedSubject] = useState("");
  const [selectedGrade, setSelectedGrade] = useState(0);
  const [selectedTopic, setSelectedTopic] = useState("");
  const [showQuiz, setShowQuiz] = useState(false); // State to control quiz display
  const [availableTopicsForSubject, setAvailableTopicsForSubject] = useState([]);
  const [selectedCorrectAnswer, setSelectedCorrectAnswer] = useState(false);
  const [showSelectedStatus, setShowSelectedStatus] = useState(false)

  const [answered, setAnswered] = useState(false);
  
  const [answeredQuestionsLog, setAnsweredQuestionsLog] = useState([]);

  const [fetchingQuestions, setFetchingQuestions] = useState(false);

  useEffect(() => {
    if (selectedSubject !== "" && selectedGrade !== 0 && selectedTopic !== "") {
      fetchQuizzes();
    }
  }, [selectedSubject, selectedGrade, selectedTopic]);


  const availableTopics = {
    "Mathematics": ["Algebra", "Trigonometry", "Functions and Graphs", "Financial Mathematics"],
    "Life Sciences": ["Biology", "Botany", "Zoology", "Ecology"],
    "Physical Sciences": ["Physics", "Chemistry", "Astronomy", "Geology"],
  };

  useEffect(() => {
    if (selectedSubject) {
      setAvailableTopicsForSubject(availableTopics[selectedSubject] || []);
    }
  }, [selectedSubject]);

  const fetchQuizzes = async () => {
    try {
      setFetchingQuestions(true);
      const apiUrl = "https://quizzes-api-jnjr.onrender.com/quizzes/";
      const response = await axios.get(apiUrl, {
        params: {
          subject: selectedSubject,
          grade: selectedGrade,
          topic: selectedTopic,
        },
      });

      const quizData = response.data;
      const shuffledQuestions = shuffleArray(quizData);

      setQuizzes(shuffledQuestions);
      setCurrentQuizIndex(0);
      setScore(0);
      setSelectedAnswer(null);
      setShowQuiz(true); // Display quiz after fetching quizzes
    } catch (error) {
      console.error("Error fetching quizzes:", error);
    } finally {
      setFetchingQuestions(false); // Whether successful or not, set fetching back to false
    }
  };

 



  const shuffleArray = (array) => {
    const shuffledArray = [...array];
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }
    return shuffledArray;
  };

 
  const filteredQuestions = quizzes?.filter(
    (quiz) =>
      quiz.subject === selectedSubject &&
      quiz.topic === selectedTopic &&
      quiz.grade === Number(selectedGrade) &&
      !answeredQuestionsLog.some((answeredQuestion) => answeredQuestion.question === quiz.question)
  );


  const handleAnswerSelection = (answer) => {
    if (!answered) {
      setSelectedAnswer(answer);
      setAnswered(true); // Mark the question as answered.
      if (answer.is_correct) {
        setScore(score + 1);
        setSelectedCorrectAnswer(true);
      } else {
        setSelectedCorrectAnswer(false);
      }
      setShowSelectedStatus(true);
    }
  };


  const handleNextQuestion = () => {
    if (currentQuizIndex + 1 < filteredQuestions.length) {
      setCurrentQuizIndex(currentQuizIndex + 1);
      setSelectedAnswer(null);
      setShowSelectedStatus(false);
      setAnswered(false); // Reset the 'answered' state for the next question.
    }
  };

  const renderAnswers = () => {

    const currentQuiz = filteredQuestions[currentQuizIndex];
    if (currentQuiz && currentQuiz.answers) {

      // const shuffledAnswers = shuffleArray(currentQuiz.answers);      
      return currentQuiz.answers.map((answer, index) => (
        <button
          key={index}
          onClick={() => handleAnswerSelection(answer)}
          disabled={answered} // Disable the button if the user has already answered the question.
          className={
            selectedAnswer === answer
              ? answer.is_correct
                ? "correct  btn btn-success text-light m-2"
                : "wrong  btn btn-danger text-light m-2"
              : "btn btn-dark text-light m-2"
          }
        >
          {answer.answer_text}
        </button>
      ));
    }
    return null;
  };

  
  


  return (
    <div className="container  mt-5 mb-5">

      <div className="my-5">
        <ul class="my-list">
          <li>
            Questions , Grades , Subjects and Topics will be added continuously
          </li>
        </ul>
      </div>

      <div className="my-4">
        <label className="px-1">
          Subject:
          <select
            value={selectedSubject}
            onChange={(e) => setSelectedSubject(e.target.value)}
          >
            <option value="">Select Subject</option>
            <option value="Mathematics">Mathematics</option>

          </select>
        </label>
        <label className="px-1">
          Grade:
          <select
            value={selectedGrade}
            onChange={(e) => setSelectedGrade(e.target.value)}
          >
            <option value="">Select Class</option>

            <option value="12">Grade 12</option>
          </select>
        </label>
        <label className="px-1">
          Topic:
          <select
            value={selectedTopic}
            onChange={(e) => setSelectedTopic(e.target.value)}
          >
            <option value="">Select Topic</option>
            {availableTopicsForSubject.map((topic) => (
              <option key={topic} value={topic}>
                {topic}
              </option>
            ))}
          </select>
        </label>
        <button className="btn btn-dark text-light mx-1" onClick={fetchQuizzes}>
          {fetchingQuestions ? "Loading..." : "Get Questions"}
        </button>
      </div>

      {showQuiz && filteredQuestions.length > 0 ? ( // Display quiz only when showQuiz is true and quizzes are available
        <div>
          <h6 className="text-primary">
            Question {currentQuizIndex + 1} / {filteredQuestions.length}
          </h6>
          {showSelectedStatus ? (
            <h2 className={selectedCorrectAnswer ? "text-success" : "text-danger"}>{selectedCorrectAnswer ? "Correct" : "Incorrect"}</h2>
          ) : ""}


          {filteredQuestions[currentQuizIndex]?.question && (
            <p className="text-success">{filteredQuestions[currentQuizIndex].question}</p>
          )}
          <div>{renderAnswers()}</div>
          {selectedAnswer && (
            <p className="method">
              Method: {filteredQuestions[currentQuizIndex].methods}
            </p>
          )}
          {currentQuizIndex + 1 < filteredQuestions.length ? (
            <button
              className="btn btn-warning my-2"
              onClick={handleNextQuestion}
            >
              Next Question
            </button>
          ) : (

            <div>
              <h3>Last Question</h3>
              <p>
                Your Score: {score}/{filteredQuestions.length}
              </p>
            </div>
          )}
        </div>
      ) : (
        <p>
          {showQuiz
            ? "No quizzes available."
            : "Please select subject, grade, and topic to get questions."}
        </p>
      )}

    </div>
  );
};

export default QuizComponent;
