import React, { useContext } from "react";
import CommunityContext from "./Contexts/CommunityContext";
import image1 from "./images/create-community1.svg"

function CreateCommunity() {
  const values = useContext(CommunityContext);

  return (
    <>
      <div className="container xs-container-fluid mt-5 mb-5">
        <h3>Create Group</h3>

        <div className="create-community-image-list-container mb-3">
          <div>
            <img src={image1} />
          </div>
          <ul class="my-list">
            <li>Enter subject name and topic in the form below.</li>
            <li>Upload group profile image by clicking on the "Choose file" button.</li>
            <li>Press Create and navigate to your created groups page to start adding content.</li>
          </ul>
        </div>
        <h4 className="text-success">Form</h4>
        <form
          onSubmit={values && values.sendSubject}
          className="d-flex flex-column flex-md-row"
        >
          <input
            style={{ width: "200px" }}
            ref={values.nameRef}
            onChange={(e) => values.setFormValue(e.target.value)}
            className="form-control me-sm-2 m-1 mb-2 mb-sm-0 p-2 w-100"
            type="text"
            placeholder="Name"
          />
          <input
            style={{ width: "200px" }}
            ref={values.descriptionRef}
            
            className="form-control me-sm-2 m-1 mb-2 mb-sm-0 p-2  w-100"
            type="text"
            placeholder="Topic"
          />

          <input
            type="file"
            onChange={values.handleFileChange}
            className="form-control-file m-1 mb-2 mb-sm-0  p-2 w-100"
          />

          <button
            className="btn btn-dark text-light m-1 my-2 my-sm-0  p-2"
            type="submit"
            disabled={!values.formValue || values.isCreating}
          >
            {values.isCreating ? "Creating..." : "Create"}
          </button>
        </form>
       
      </div>
    </>
  );
}

export default CreateCommunity;
