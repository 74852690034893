import React, { useEffect, useState } from "react";
import firebase from "firebase/compat/app";
import { projectFirestore as firestore } from "../firebase"; // Import projectFirestore
import UserQuizScores from "../Pages/UserQuizScores";
import UserLeaderboard from "../Pages/UserLeaderboard";

function UserData() {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [userId, setUserId] = useState("");
  const [selectedSchool, setSelectedSchool] = useState("");

  useEffect(() => {
    async function getUserData() {
      try {
        const currentUser = firebase.auth().currentUser;
        setUserId(currentUser.uid);

        // Fetch user data from usersDoc
        const usersDocSnapshot = await firestore
          .collection("usersCollection")
          .doc("usersDoc")
          .get();

        if (usersDocSnapshot.exists) {
          const usersData = usersDocSnapshot.data().users;
          const userData = usersData.find(
            (user) => user.uid === currentUser.uid
          );
          setUserData(userData);
          setSelectedSchool(userData.school); // Set selected school initially
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
      setLoading(false);
    }

    getUserData();
  }, []);

  const handleSchoolChange = async (e) => {
    const newSchool = e.target.value;
    try {
      await firestore
        .collection("usersCollection")
        .doc("usersDoc")
        .update({
          // Update the school field of the user in usersDoc
          users: userData.map((user) =>
            user.uid === userId ? { ...user, school: newSchool } : user
          ),
        });
      setSelectedSchool(newSchool); // Update selected school state
    } catch (error) {
      console.error("Error updating school:", error);
    }
  };

  if (loading) {
    return <div>Loading user data...</div>;
  }

  return (
    <div className="mt-5 mb-5">
      <h4>My Information</h4>
      {userData && (
        <div className="profile-info-grid">
          <div className="m-2" style={{ width: "200px", height: "200px"}}>
            <img style={{width:"100%",height:"100%", objectFit:"cover" }} src={userData.photoURL} alt="no image" />
          </div>

          <div className="m-2">
            <h5 className="fw-bolder">
              {userData?.displayName}
            </h5>
            <p>
              <strong>Email:</strong> {userData?.email}
            </p>
            <p>
              <strong>Code:</strong> {userData?.code}
            </p>

            <div className="w-50">
              <label htmlFor="school">Edit School:</label>
              <select
                id="school"
                value={selectedSchool}
                onChange={handleSchoolChange}
              >
                <option value="School 1">School 1</option>
                <option value="School 2">School 2</option>
              </select>
            </div>
          </div>
        </div>
      )}
      <UserLeaderboard username={userData?.displayName} />
      <UserQuizScores userId={userId} />
    </div>
  );
}

export default UserData;
