import React, { useState, useContext,useEffect } from "react";
import { auth } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link } from "react-router-dom";
import SubjectsContext from "./Contexts/SubjectContext";
import image1 from "./images/create-community1.svg"
import subject_img from "./subject.svg"
import TeacherPayment from "../Payment/TeacherPayment"
import { useIntersectionObserver } from './useIntersectionObserver';

function DisplayUserSubjects() {
  const values = useContext(SubjectsContext);
  const [user] = useAuthState(auth);
  const [dropdownOpen, setDropdownOpen] = useState({}); // Track dropdown state for each subject
  const [showPaymentPopup, setShowPaymentPopup] = useState(false); // State for popup visibility
  
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
  }, []);

  const toggleDropdown = (subjectId) => {
    setDropdownOpen((prevState) => ({
      ...prevState,
      [subjectId]: !prevState[subjectId],
    }));
  };

  const userSubjects =
    values.subjects &&
    values.subjects.filter((subject) => subject.uid === user.uid);

  const togglePaymentPopup = () => {
    setShowPaymentPopup(!showPaymentPopup);
  };
  
  const setVisible = (element) => {
    element.classList.add('visible');
  };

  useIntersectionObserver(setVisible);
  return (
    <>
      <div style={{ width: '90%', margin: 'auto' }} className="mt-5 mb-5">
        <div className="create-community-image-list-container mt-2 mb-1">

          {userSubjects && userSubjects.length > 0 ? (
            <ul class="my-list">
              <li>Below are the subjects you created.</li>
              <li>Click to a subject to start adding content.</li>
            </ul>
          ) : (
            <p>You do not have any Subjects</p>
          )}
          <div>
            <img src={image1} />
          </div>
        </div>
        <section className="py-5">
          <div className="mt-5">
            <div className="subjects">
              {userSubjects &&
                userSubjects.map((subject, index) => (
                  <div className=" mb-5 subject" key={index}>
                    {subject.is_published && !subject.is_reviewed ? (
                      <p className="my-2">waiting for review</p>
                    ) : subject.is_reviewed ? (
                      <p className="my-2">published</p>
                    ) : (
                      <div className="mb-2">
                        <div className="my-2">{subject.review && <p>{subject.review}</p>}</div>
                        {/* Payment Popup */}
                        {showPaymentPopup && (
                          <div className="popup">
                            <div className="popup-inner">
                              <button onClick={() => setShowPaymentPopup(false)}><i class="fas fa-times"></i></button>
                              <TeacherPayment

                                name={subject.name}
                                price={subject.price}
                                description={subject.description}
                                sub_id={subject.sub_id}
                                displayName={subject.displayName}
                                uid={subject.uid}
                              />
                            </div>
                          </div>
                        )}
                        <button
                          className="btn btn-success"
                          onClick={() => values.handlePublish(subject.name)} // Toggle popup visibility
                        >
                          upload subject
                        </button>
                        {" "}
                        <button
                          onClick={() => values.deleteSubject(subject.name)}
                          className="btn btn-outline-dark mx-3"
                        >
                          <i className="fas fa-trash"></i>
                        </button>
                      </div>
                    )}

                    <div>
                      <Link
                        to={`${subject.name}`}
                        style={{ textDecoration: "none" }}
                        className="col mb-2"
                      >
                        <div>
                          <img
                            className="subject-img"
                            src={
                              subject.file
                                ? subject.file.url
                                : subject_img
                            }
                            alt="..."
                          />

                          <div className="subjects-cont text-light bg-dark px-2">
                            <div>
                              <p className="fw-bolder">{subject.name}</p>
                              {subject.students && (
                                <p>{subject.students.length} students</p>
                              )}
                            </div>
                          </div>
                        </div>
                      </Link>
                      <div className="dropdown mb-5">
                        <button
                          className="btn btn-secondary dropdown-toggle"
                          type="button"
                          onClick={() => toggleDropdown(index)}
                        >
                          Students
                        </button>
                        {dropdownOpen[index] && (
                          <div className="dropdown-menu show">
                            <ol>
                              {subject.students &&
                                subject.students.map((std) => (
                                  <li key={std}>{std}</li>
                                ))}
                            </ol>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                ))}
            </div>
          </div>
        </section>
      </div>

    </>
  );
}

export default DisplayUserSubjects;
