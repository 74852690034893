import React, { useState, useContext } from "react";
import SubjectsContext from "./Contexts/SubjectContext";
import CommunityContext from "./Contexts/CommunityContext";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import Quill styles

function CreateQuizQuestion({ lesson_id, subject, type }) {
  let cont;

  if (type === "community") {
    cont = CommunityContext;
  } else {
    cont = SubjectsContext;
  }

  const values = useContext(cont);

  const [loading, setLoading] = useState(false);

  const [newQuestion, setNewQuestion] = useState({
    grade: 0,
    subject: subject,
    topic: lesson_id,
    question: "",
    methods: "",
    answers: [
      { answer_text: "", is_correct: false },
      { answer_text: "", is_correct: false },
      { answer_text: "", is_correct: false },
      { answer_text: "", is_correct: false },
    ], // Initialize with 4 distinct answer options
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setNewQuestion({
      ...newQuestion,
      [name]: value,
    });
  };

  const handleAnswerChange = (index, event) => {
    const { name, value, type, checked } = event.target;
    const newAnswers = [...newQuestion.answers];
    newAnswers[index][name] = type === "checkbox" ? checked : value;
    setNewQuestion({
      ...newQuestion,
      answers: newAnswers,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if question or methods are empty
    if (!newQuestion.question.trim() || !newQuestion.methods.trim()) {
      alert("Question and methods fields cannot be empty!");
      return;
    }

    // Check if any answer option is empty
    const hasEmptyAnswer = newQuestion.answers.some(
      (answer) => !answer.answer_text.trim()
    );

    if (hasEmptyAnswer) {
      alert("Answer options cannot be empty!");
      return;
    }

    setLoading(true);

    try {
      // Call your API or function to add the quiz
      await values.addQuizToSubject(e, subject, lesson_id, newQuestion);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleQuillChange = (name, value) => {
    setNewQuestion({
      ...newQuestion,
      [name]: value,
    });
  };

  const handleAnswerQuillChange = (index, value) => {
    const newAnswers = [...newQuestion.answers];
    newAnswers[index].answer_text = value;
    setNewQuestion({
      ...newQuestion,
      answers: newAnswers,
    });
  };

  const modules = {
    toolbar: {
      container: [
        [{ header: [1, 2, 3, false] }],
        
        [{ list: "ordered" }, { list: "bullet" }],
        ["image"],
        [{ align: [] }]
      ]
    },
  };

  return (
    <div className="mt-2">
     
      <form onSubmit={(e) => handleSubmit(e)}>
        <div>
          <label htmlFor="methods">Topic:</label>
          <ReactQuill
            id="methods"
            value={newQuestion.methods}
            onChange={(value) => handleQuillChange("methods", value)}
            placeholder="Type your Topic here"
            className="react-quill"
          />
        </div>

        <div>
          <label htmlFor="question">Question:</label>
          <ReactQuill
            id="question"
            value={newQuestion.question}
            onChange={(value) => handleQuillChange("question", value)}
            placeholder="Type your question here"
            className="react-quill"
            modules={modules}
          />
        </div>

        <div>
          <h6 className="text-success mt-4">Answers:</h6>
          {newQuestion.answers.map((answer, index) => (
            <div key={index}>
              <ReactQuill
                value={answer.answer_text}
                onChange={(value) => handleAnswerQuillChange(index, value)}
                placeholder={`Type your answer for option ${index + 1}`}
                className="react-quill"
                modules={modules}
              />
              <label>
                <input
                  type="checkbox"
                  name="is_correct"
                  checked={answer.is_correct}
                  onChange={(e) => handleAnswerChange(index, e)}
                />
                Select if answer is Correct
              </label>
            </div>
          ))}
        </div>
        <div className="my-2">
          <button
            type="submit"
            className="btn px-3 py-2 m-1 my-5 btn-success"
            disabled={loading} // Disable the button when loading is true
          >
            {loading ? "Submitting..." : "Submit Quiz"}
          </button>
        </div>
      </form>
    </div>
  );
}

export default CreateQuizQuestion;
