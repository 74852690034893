import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import SubjectsContext from "./Contexts/SubjectContext";
import subject_img from "./subject.svg"
function ReviewSubjects() {

  
  const values = useContext(SubjectsContext);
  const [sub, setSub] = useState("");

  const publishedSubjects = values.subjects?.filter(
    (subject) => subject.is_published === true &&
    subject.is_reviewed === false
  );
  
  
  const filteredSubjects = publishedSubjects?.filter(
    (subject) =>
      subject.name.toLowerCase().includes(sub.toLowerCase()) ||
      subject.description.toLowerCase().includes(sub.toLowerCase()) ||
      subject.displayName.toLowerCase().includes(sub.toLowerCase()) 
      
  );

  return (
    <>
      <div className="mt-5 mb-5">
        <form className="my-5 my-1 d-flex px-lg-5 px-md-2 px-0">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <input
                className="form-control me-sm-2 input"
                type="search"
                placeholder="Search"
                onChange={(e) => setSub(e.target.value)}
              />
            </div>
          </div>
        </form>
       
        <section className="py-5 ">
          <div className="px-lg-5 px-md-2 px-0 mt-5">
            <div className="row gx-4 gx-lg-5 row-cols-1 row-cols-md-2 row-cols-xl-2 justify-content-center">
              {
               
              filteredSubjects &&
                filteredSubjects.map((subject) => (
                  <div>
                    <div
                      
                    >
                      <div className="col mb-5">
                        <div 
                        className="card w-100 h-100">
                          <Link  to={`${subject.name}`}
                         state={{ data: subject }}
                         style={{ textDecoration: "none", color: "inherit" }}>
                          <img
                            className="card-img-top w-100 h-100"
                            src={
                              subject.file
                                ? subject.file.url
                                : subject_img
                            }
                            alt="..."
                          />
                          </Link>
                          <div className="card-body p-2">
                            <div className="text-center">
                              <p className="fw-bolder">{subject.name}</p>
                            </div>
                            <div className="text-center">
                              <p>{subject.description}</p>
                            </div>
                          </div>

                          <div className="card-footer p-1 pt-0 border-top-0 bg-transparent">
                            <div className="text-center">
                              <p>Instructor : {subject.displayName}</p>
                            </div>
                            {subject.is_published && !subject.is_reviewed ? (
                              <div>
                                <input
                                  style={{ width: "200px" }}
                                  ref={values.reviewRef}
                                  className="w-100 form-control me-sm-2 p-2"
                                  type="text"
                                  placeholder="write review"
                                />
                                <div className="d-flex p-2 ">
                                <button
                                  className="btn w-100 btn-success m-1"
                                  onClick={(e) =>
                                    values.handleReview(subject.name)
                                  }
                                >
                                  Publish
                                </button>

                                <button
                                  className="w-100 btn btn-danger m-1"
                                  onClick={(e) =>
                                    values.handleReEdit(subject.name)
                                  }
                                >
                                  Re Edit
                                </button>
                                </div>
                              </div>
                            ) : subject.is_reviewed ? (
                              <div className="text-center text-success">
                              <h1>Published</h1>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default ReviewSubjects;
