import React, { useState, useContext } from "react";
import SubjectsContext from "./Contexts/SubjectContext";
import CommunityContext from "./Contexts/CommunityContext";
import { auth, projectFirestore as firestore } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import StarIcons from "./StarIcons";
// UI component for adding a review and rating
function ReviewForm({ SubjectName, subject, type }) {
  let cont;

  if (type === "community") {
    cont = CommunityContext;
  } else {
    cont = SubjectsContext;
  }

  const values = useContext(cont);

  // const { addReviewAndRating } = useContext(SubjectsContext);
  const [review, setReview] = useState("");
  const [rating, setRating] = useState(1); // Default rating is 1
  const [show, setShow] = useState(false);
  const { displayName } = auth.currentUser;

  const handleSubmit = async (e) => {
    e.preventDefault();
    await values.addReviewAndRating(SubjectName, review, rating, displayName);

    setReview("");
    setRating(1);
  };

  return (
    <div className="my-2">
     
      {!show ? (
        <>
          <button
            onClick={() => setShow(true)}
            className="btn btn-outline-success my-5"
          >
            Reviews
          </button>
        </>
      ) : (
        <div className="mt-5">
         <p className="fw-bolder text-success my-4">Reviews</p>
          <ul>
            {subject.reviews?.map((review, index) => (
              <li key={index}>
                <p className="fw-bolder text-primary">{review.user}</p>
                <p>Rating: {review.rating}</p>
                <StarIcons averageRating={review.rating} />
                <p>Review: {review.review}</p>
              </li>
            ))}
          </ul>

          <form onSubmit={handleSubmit}>
            <div>
              <label htmlFor="review">Write Your Review:</label>
              <input
                type="text"
                id="review"
                value={review}
                onChange={(e) => setReview(e.target.value)}
                required
                className="m-2"
                style={{
                  width: "97%",
                  maxWidth: "400px",
                  height: "38px",
                  borderRadius: "6px",
                }}
              />
            </div>
            <div>
              <label htmlFor="rating">Rating:</label>
              <select
                id="rating"
                value={rating}
                onChange={(e) => setRating(parseInt(e.target.value))}
                className="m-2"
                style={{ width: "220px", height: "38px", borderRadius: "6px" }}
              >
                {Array.from({ length: 5 }, (_, index) => index + 1).map(
                  (value) => (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  )
                )}
              </select>
            </div>
            <button
              className="btn btn-outline-success m-2 my-4"
              style={{ width: "200px", height: "38px", borderRadius: "6px" }}
              type="submit"
            >
              Submit Review
            </button>
          </form>

          <button
            onClick={() => setShow(false)}
            className="btn btn-outline-danger my-3"
          >
            Close Reviews
          </button>
        </div>
      )}
    </div>
  );
}

export default ReviewForm;
