import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { auth, projectFirestore as firestore } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";

function Navigation() {
  const [user] = useAuthState(auth);
  const [navBackground, setNavBackground] = useState(false);
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [userDoc, setUserDoc] = useState(null);
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Loading state for userDoc

  useEffect(() => {
    if (user) {
      // Fetch users from usersCollection
      const unsubscribeUsers = firestore
        .collection("usersCollection")
        .doc("usersDoc")
        .onSnapshot((doc) => {
          const usersData = doc.data()?.users || [];
          setUsers(usersData);
          const s_user = usersData?.find((u) => u.displayName === user.displayName);
          setUserDoc(s_user);
          setIsLoading(false); // Set loading to false after fetching data
        });

      return () => {
        unsubscribeUsers();
      };
    } else {
      setIsLoading(false); // No user, so we are not loading
    }
  }, [user]);

  const handleScroll = () => {
    const show = window.scrollY > 100;
    if (show) {
      setNavBackground(true);
      setIsNavOpen(false);
    } else {
      setNavBackground(false);
    }
  };

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      const show = window.scrollY > 100;
      if (show) {
        setNavBackground(true);
      } else {
        setNavBackground(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navStyle = {
    backgroundColor: navBackground ? "black" : "transparent",
    transition: "background-color 0.5s ease-out",
    position: "fixed",
    lineHeight: "2rem",
    top: 0,
    left: 0,
    right: 0,
    zIndex: 100,
  };

  return (
    <>
      <nav
        className="navbar navbar-expand-lg navbar-dark bg-dark"
        style={navStyle}
      >
        <div className="px-4 px-lg-5 w-100">
          <button
            className="navbar-toggler nav-menu text-info p-3"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target={isNavOpen ? "" : "#navbarSupportedContent"}
            aria-controls="navbarSupportedContent"
            aria-expanded={isNavOpen ? "true" : "false"}
            aria-label="Toggle navigation"
            onClick={toggleNav}
            
          >
            {!isNavOpen ? (
              <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <span className="fas fa-sharp fa-solid fa-bars fa-beat text-info"></span>
              <span style={{ fontSize: "12px", color: "white", marginTop: "4px" }}>Menu</span>
            </div>
            ) : (
              // Display 'X' icon when navigation is open
              <span className="fas fa-times"></span>
            )}
          </button>
          <div
            className={`collapse navbar-collapse navbar-search-links ${
              isNavOpen ? "show" : ""
            }`}
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav me-auto mb-2 mb-lg-0 ms-lg-4">
              <li className="nav-item fw-bolder my-2 my-sm-0">
                <a className="nav-link active" aria-current="page" href="#!">
                  <Link
                    to="/"
                    style={{ textDecoration: "none" }}
                    onClick={toggleNav}
                    className="link"
                  >
                    Home
                  </Link>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  <Link
                    to="/display-subjects"
                    style={{ textDecoration: "none" }}
                    onClick={toggleNav}
                    className="link"
                  >
                    Subjects
                  </Link>
                </a>
              </li>

              <li className="nav-item dropdown">
                {/* Dropdown */}
                <a
                  className="nav-link dropdown-toggle text-primary"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Leaderboards
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <Link
                      to="/all-quiz-scores"
                      style={{ textDecoration: "none" }}
                      onClick={toggleNav}
                      className="dropdown-item"
                    >
                      Quiz Scores
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/leaderboards"
                      style={{ textDecoration: "none" }}
                      onClick={toggleNav}
                      className="dropdown-item"
                    >
                      Leaderboards
                    </Link>
                  </li>
                </ul>
              </li>
             {/*
              <li className="nav-item dropdown">
                  
                <a
                  className="nav-link dropdown-toggle text-primary"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Study Materials
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <Link
                      to="/display"
                      style={{ textDecoration: "none" }}
                      onClick={toggleNav}
                      className="dropdown-item"
                    >
                      Download Study Materials
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/upload"
                      style={{ textDecoration: "none" }}
                      onClick={toggleNav}
                      className="dropdown-item"
                    >
                      Share Study Materials
                    </Link>
                  </li>
                </ul>
              </li>
              */}
              {isLoading ? (
                <li className="nav-item">
                  <span className="nav-link">Loading...</span>
                </li>
              ) : (
                userDoc && userDoc.is_instructor && (
                  <li className="nav-item dropdown">
                    {/* Dropdown for "My Subjects" and "Create Subjects" */}
                    <a
                      className="nav-link dropdown-toggle text-primary"
                      href="#"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Create Subjects
                    </a>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="navbarDropdown"
                    >
                      <li>
                        <Link
                          to="/display-user-subjects"
                          style={{ textDecoration: "none" }}
                          onClick={toggleNav}
                          className="dropdown-item"
                        >
                          My Subjects
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/create-subjects"
                          style={{ textDecoration: "none" }}
                          onClick={toggleNav}
                          className="dropdown-item"
                        >
                          Create New Subject
                        </Link>
                      </li>
                    </ul>
                  </li>
                )
              )}

              <li className="nav-item">
                <a className="nav-link" href="#">
                  <Link
                    to="/instructors"
                    style={{ textDecoration: "none" }}
                    onClick={toggleNav}
                    className="link"
                  >
                    Teachers
                  </Link>
                </a>
              </li>
              {user &&
                user.displayName &&
                user.displayName.includes("Mphashi") && (
                  <>
                    <li className="nav-item">
                      <a className="nav-link" href="#">
                        <Link
                          to="/review-subjects"
                          style={{ textDecoration: "none" }}
                          onClick={toggleNav}
                          className="link"
                        >
                          Review
                        </Link>
                      </a>
                    </li>
                   
                  </>
                )}
            </ul>
            <div>
              {user ? (
                <p className="fw-bolder my-2 my-sm-0 text-center">
                  <a className="nav-link" href="#">
                    <Link
                      to="/profile"
                      style={{ textDecoration: "none", color: "white" }}
                      onClick={toggleNav}
                    >
                      <i className="fas fa-user"></i>
                      <br />
                      <small>{user?.displayName}</small>
                    </Link>
                  </a>
                </p>
              ) : (
                <div className="d-flex">
                  <p className=" my-2 mx-2 my-sm-0">
                    <a className="nav-link" href="#">
                      <Link
                        className="text-primary fw-bolder"
                        to="/login"
                        style={{ textDecoration: "none", color: "white" }}
                        onClick={toggleNav}
                      >
                        login
                      </Link>
                    </a>
                  </p>
                  <p className="mx-2 my-2 my-sm-0">
                    <a className="nav-link" href="#">
                      <Link
                        className="text-primary fw-bolder"
                        to="/signup"
                        style={{ textDecoration: "none", color: "white" }}
                        onClick={toggleNav}
                      >
                        sign up
                      </Link>
                    </a>
                  </p>
                
                  <p className="mx-2 my-2 my-sm-0">
                    <a className="nav-link" href="#">
                      <Link
                        className="text-light"
                        to="/instructor-signup"
                        style={{ textDecoration: "none", color: "white" }}
                        onClick={toggleNav}
                      >
                        Register as Teacher
                      </Link>
                    </a>
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </nav>
      {/**/}
      <header
        className="py-2"
        style={{ backgroundColor: "#393f4d", marginTop: "34px" }}
      >
        <div className="container px-2 px-lg-2 my-2">
          <div className="text-start text-light">
            <h4 className="display-5 text-success"></h4>
          </div>
        </div>
      </header>
    </>
  );
}

export default Navigation;
