import React, { useContext, useState ,useEffect } from "react";
import { auth } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link } from "react-router-dom";
import CommunityContext from "./Contexts/CommunityContext";
import Fuse from "fuse.js";
import { useNavigate } from "react-router-dom";
import StarIcons from "./StarIcons";
import image1 from "./images/subjects5.svg";
import subject_img from "./subject.svg";
import { useIntersectionObserver } from './useIntersectionObserver';

function DisplayCommunities() {
  const values = useContext(CommunityContext);
  const [user] = useAuthState(auth);
  const [sub, setSub] = useState("");
  const [showEnrolled, setShowEnrolled] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
  }, []);

  const fuseOptions = {
    keys: ["name", "description", "displayName"],
    includeScore: true,
    threshold: 2.3, // Adjust this threshold as needed
  };

  const reviewedSubjects = values.subjects?.filter(
    (subject) => subject.is_reviewed === false
  );

  const [sortMethod, setSortMethod] = useState("default");
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortPriceOrder, setSortPriceOrder] = useState("asc");

  // Existing code...

  const sortSubjectsByUsersAndPrice = (method, order, priceOrder) => {
    const subjectsToSort = showEnrolled ? enrolledSubjects : filteredSubjects;

    const sortedSubjects = subjectsToSort?.slice().sort((a, b) => {
      const usersA = a.students ? a.students.length : 0;
      const usersB = b.students ? b.students.length : 0;
      const priceA = a.price || 0;
      const priceB = b.price || 0;

      const averageRatingA = calculateAverageRating(a.reviews);
      const averageRatingB = calculateAverageRating(b.reviews);

      if (order === "asc") {
        if (method === "users") {
          const usersComparison = usersA - usersB;
          return usersComparison !== 0 ? usersComparison : priceA - priceB;
        } else if (method === "price") {
          const priceComparison = priceA - priceB;
          return priceComparison !== 0 ? priceComparison : usersA - usersB;
        } else if (method === "rating") {
          const ratingComparison = averageRatingA - averageRatingB;
          return ratingComparison !== 0 ? ratingComparison : usersA - usersB;
        }
      } else {
        if (method === "users") {
          const usersComparison = usersB - usersA;
          return usersComparison !== 0 ? usersComparison : priceB - priceA;
        } else if (method === "price") {
          const priceComparison = priceB - priceA;
          return priceComparison !== 0 ? priceComparison : usersB - usersA;
        } else if (method === "rating") {
          const ratingComparison = averageRatingB - averageRatingA;
          return ratingComparison !== 0 ? ratingComparison : usersB - usersA;
        }
      }
      return 0;
    });

    return sortedSubjects;
  };

  // Existing code...

  const filteredSubjects = reviewedSubjects?.filter(
    (subject) =>
      subject.name.toLowerCase().includes(sub.toLowerCase()) ||
      subject.description.toLowerCase().includes(sub.toLowerCase()) ||
      subject.displayName.toLowerCase().includes(sub.toLowerCase())
  );
  const enrolledSubjects = reviewedSubjects?.filter(
    (subject) =>
      subject.students?.includes(user.displayName) &&
      subject.name.toLowerCase().includes(sub.toLowerCase())
  );

  const fuzzySearch = (query) => {
    const fuse = new Fuse(filteredSubjects, fuseOptions);
    const result = fuse.search(query);

    const fuzzyFilteredSubjects = result.map((item) => item.item);

    return fuzzyFilteredSubjects;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSub("");
  };

  function calculateAverageRating(reviews) {
    if (reviews.length === 0) {
      return 0; // No reviews, so average is 0
    }

    const totalRating = reviews.reduce((sum, review) => sum + review.rating, 0);
    return totalRating / reviews.length;
  }

  const setVisible = (element) => {
    element.classList.add('visible');
  };

  useIntersectionObserver(setVisible);


  return (
    <div style={{ width: "90%", margin: "auto" }} className=" mt-2 mb-5">
      <div className="create-community-image-list-container mt-2 mb-1">
        <div>
          <form
            className="sm-mx-1 my-5 my-1 d-fle"
            onSubmit={(e) => e.preventDefault()}
          >
            <div className="row sm-mx-1">
              <div className="col-12">
                <input
                  style={{ width: "100%", maxWidth: "600px" }}
                  className="sm-mx-1 form-control me-sm-2 input"
                  type="search"
                  placeholder="Filter Groups"
                  value={sub}
                  onChange={(e) => setSub(e.target.value)}
                />
              </div>
            </div>
          </form>

          <div className="subjects-filter-grid my-2">
            <button
              onClick={(e) => setShowEnrolled(false)}
              className="btn btn-success m-1 text-light"
            >
              All Groups
            </button>
            <button
              onClick={(e) => setShowEnrolled(true)}
              className="btn btn-secondary m-1 text-light"
            >
              joined groups
            </button>
            <div className="dropdown m-1">
              <button
                className="btn btn-dark dropdown-toggle text-light px-3"
                type="button"
                id="sortDropdown"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Sort by
              </button>
              <ul className="dropdown-menu" aria-labelledby="sortDropdown">
                <li>
                  <button
                    className="dropdown-item my-1"
                    onClick={() => {
                      setSortMethod("users");
                      setSortOrder("desc");
                      setSortPriceOrder("asc");
                    }}
                  >
                    Learners
                  </button>
                </li>

                <li>
                  <button
                    className="dropdown-item my-1"
                    onClick={() => {
                      setSortMethod("rating");
                      setSortOrder("desc");
                      setSortPriceOrder("asc");
                    }}
                  >
                    Ratings
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div style={{ maxHeight: "260px", maxWidth: "260px" }} className="my-5">
          <img src={image1} />
        </div>
      </div>
      <div>

        
        <section className=" mt-4">
          <div className="px-0 px-lg-1 mt-3">
            {!showEnrolled ? (
              <div>
                <h5 className="mb-5">All Groups</h5>
                <div className="subjects">
                  {sortSubjectsByUsersAndPrice(
                    sortMethod,
                    sortOrder,
                    sortPriceOrder
                  )?.map((subject) => (
                    <div key={subject.id} className="subject">
                      <div className="my-1">
                        {subject.students
                          ?.map((student) => student)
                          .includes(user?.displayName) ||
                        user.displayName === subject.displayName ? (
                          <span className="badge badge-enrolled">joined</span>
                        ) : (
                          <div>
                            {subject.courseStructure
                              ?.map((student) => student)
                              .includes(user?.displayName) ||
                            user.displayName === subject.displayName ? (
                              <span className="badge badge-waiting">
                                requested to join...
                              </span>
                            ) : (
                              <span className="badge badge-not-enrolled">
                                not joined
                              </span>
                            )}
                          </div>
                        )}
                      </div>
                      <div
                        style={{ textDecoration: "none" }}
                        className="text-primary m-1 fw-bolder"
                      >
                        Created by - {subject.displayName}
                      </div>
                      <div>
                        <Link
                          to={`${subject.name}`}
                          state={{ data: subject }}
                          style={{ textDecoration: "none", color: "inherit" }}
                        >
                          <div className="col mb-5">
                            <div className=" bg-dark text-light position-relative">
                              <img
                                className="subject-img"
                                src={
                                  subject.file ? subject.file.url : subject_img
                                }
                                alt="..."
                              />
                              <div
                                className="subjects-cont-overlay position-absolute top-0 start-0 w-100 h-100 d-flex flex-column justify-content-center"
                                style={{
                                  backgroundColor: "rgba(0, 0, 0, 0.6)",
                                }}
                              >
                                <div className="px-2">
                                  <div>
                                    <h3 className="fw-bolder text-light">
                                      {subject.name}
                                    </h3>
                                  </div>
                                  <div>
                                    <h6 className="text-light">
                                      {subject.description.slice(0, 50)}
                                    </h6>
                                  </div>
                                  <div className="px-2 pt-0 border-top-0 bg-transparent">
                                    <div>
                                      {subject.students && (
                                        <div>
                                          <p>
                                            {subject.students.length} Learners
                                          </p>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="px-2">
                                    <p>
                                      Average Rating:{" "}
                                      {calculateAverageRating(
                                        subject.reviews
                                      ).toFixed(2)}
                                    </p>
                                    <StarIcons
                                      averageRating={calculateAverageRating(
                                        subject.reviews
                                      )}
                                    />
                                    <button className="text-center text-light bg-primary p-2 my-2">
                                      Visit Group
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div>
                <h5 className="mb-5">Joined Groups</h5>
                <div className="subjects">
                  {sortSubjectsByUsersAndPrice(
                    sortMethod,
                    sortOrder,
                    sortPriceOrder
                  )?.map((subject) => (
                    <div key={subject.id} className="subject">
                      <div>
                        {subject.students
                          ?.map((student) => student)
                          .includes(user?.displayName) ||
                        user.displayName === subject.displayName ? (
                          <span className="badge badge-enrolled">Enrolled</span>
                        ) : (
                          <span className="badge badge-not-enrolled">
                            Not Enrolled
                          </span>
                        )}
                      </div>
                      <div>
                        <div
                          style={{ textDecoration: "none" }}
                          className="text-primary fw-bolder"
                        >
                          Created by - {subject.displayName}
                        </div>
                        <Link
                          to={`${subject.name}`}
                          state={{ data: subject }}
                          style={{ textDecoration: "none", color: "inherit" }}
                        >
                          <div className="col mb-5">
                            <div className=" bg-dark text-light position-relative">
                              <img
                                className="subject-img"
                                src={
                                  subject.file ? subject.file.url : subject_img
                                }
                                alt="..."
                              />
                              <div
                                className="subjects-cont-overlay position-absolute top-0 start-0 w-100 h-100 d-flex flex-column justify-content-center"
                                style={{
                                  backgroundColor: "rgba(0, 0, 0, 0.6)",
                                }}
                              >
                                <div className="px-2">
                                  <div>
                                    <h3 className="fw-bolder text-light">
                                      {subject.name}
                                    </h3>
                                  </div>
                                  <div>
                                    <h6 className="text-light">
                                      {subject.description.slice(0, 50)}
                                    </h6>
                                  </div>
                                  <div className="px-2 pt-0 border-top-0 bg-transparent">
                                    <div>
                                    
                                      {subject.students && (
                                        <div>
                                          <p>
                                            {subject.students.length} Learners
                                          </p>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="px-2">
                                    <p>
                                      Average Rating:{" "}
                                      {calculateAverageRating(
                                        subject.reviews
                                      ).toFixed(2)}
                                    </p>
                                    <StarIcons
                                      averageRating={calculateAverageRating(
                                        subject.reviews
                                      )}
                                    />
                                    <button className="text-center text-light bg-primary p-2 my-2">
                                      Visit Group
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </section>
      </div>
    </div>
  );
}

export default DisplayCommunities;
