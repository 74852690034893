import React, { useState, useEffect, useContext } from "react";
import { UsersContext } from "./Contexts/UsersContext";

const UserQuizScores = ({ userId }) => {
  const { users } = useContext(UsersContext);
  const [usersScores, setUsersScores] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState("All");
  const [rankBy, setRankBy] = useState("averagePercentage");
  const [availableSubjects, setAvailableSubjects] = useState([]);
  const [subjectScores, setSubjectScores] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
  }, []);

  useEffect(() => {
    const allUsersScores = [];
    const subjectsSet = new Set();
    const currentUserSubjectScores = [];

    users.forEach((userData) => {
      // Only process the user if its uid matches userId
      if (userData.uid === userId) {
        const answeredQuestionsLog = userData.answeredQuestionsLog || [];
        const allQuizResults = answeredQuestionsLog.flatMap((log) => log.quizResults);

        const subjectGroups = {};

        allQuizResults.forEach((quizResult) => {
          const { subject, isCorrect } = quizResult;

          subjectsSet.add(subject);

          if (selectedSubject === "All" || subject === selectedSubject) {
            if (!subjectGroups[subject]) {
              subjectGroups[subject] = {
                totalAnswered: 0,
                totalCorrect: 0,
              };
            }

            subjectGroups[subject].totalAnswered++;
            if (isCorrect) {
              subjectGroups[subject].totalCorrect++;
            }
          }
        });

        let highestSubject = { subject: "N/A", averagePercentage: 0 };
        let lowestSubject = { subject: "N/A", averagePercentage: 100 };

        Object.keys(subjectGroups).forEach((subject) => {
          const { totalAnswered, totalCorrect } = subjectGroups[subject];
          const averagePercentage = totalAnswered > 0 ? (totalCorrect / totalAnswered) * 100 : 0;

          if (averagePercentage > highestSubject.averagePercentage) {
            highestSubject = {
              subject,
              averagePercentage,
            };
          }

          if (averagePercentage < lowestSubject.averagePercentage) {
            lowestSubject = {
              subject,
              averagePercentage,
            };
          }

          // Collect scores for each subject to display under the table
          currentUserSubjectScores.push({
            subject,
            totalAnswered,
            averagePercentage,
          });
        });

        const totalAnswered = allQuizResults.length;
        const totalCorrect = allQuizResults.filter((quizResult) => quizResult.isCorrect).length;
        const averagePercentage = totalAnswered > 0 ? (totalCorrect / totalAnswered) * 100 : 0;

        allUsersScores.push({
          userId: userData.displayName,
          totalAnswered,
          totalCorrect,
          averagePercentage,
          highestSubject,
          lowestSubject,
        });
      }
    });

    // Sort users based on selected ranking criteria
    allUsersScores.sort((a, b) => {
      if (rankBy === "averagePercentage") {
        return b.averagePercentage - a.averagePercentage;
      } else if (rankBy === "totalCorrect") {
        return b.totalCorrect - a.totalCorrect;
      }
      return 0;
    });

    setUsersScores(allUsersScores);
    setAvailableSubjects(Array.from(subjectsSet));
    setSubjectScores(currentUserSubjectScores);
  }, [selectedSubject, rankBy, users, userId]);

  const handleSubjectChange = (e) => {
    setSelectedSubject(e.target.value);
  };

  const handleRankByChange = (e) => {
    setRankBy(e.target.value);
  };

  return (
    <div className="my-5 container container-fluid">
      <h5 className="my-4 text-primary">Quiz Scores</h5>
      <table className="table">
        <thead>
          <tr>
            <th>Answered</th>
            <th>Correct</th>
            <th>Percentage</th>
            <th>Highest Subject</th>
            <th>Lowest Subject</th>
          </tr>
        </thead>
        <tbody>
          {usersScores
            .filter(
              (userScore) =>
                selectedSubject === "All" ||
                userScore.highestSubject.subject === selectedSubject
            )
            .slice(0, 20)
            .map((userScore, index) => (
              <tr key={index}>
                <td>{userScore.totalAnswered}</td>
                <td>{userScore.totalCorrect}</td>
                <td
                  className={
                    userScore.averagePercentage > 80
                      ? "text-success"
                      : userScore.averagePercentage > 50
                      ? "text-primary"
                      : userScore.averagePercentage > 30
                      ? "text-warning"
                      : "text-danger"
                  }
                >
                  {userScore.averagePercentage.toFixed(2)}%
                </td>
                <td>
                  {userScore.highestSubject.subject} (
                  {userScore.highestSubject.averagePercentage.toFixed(2)}%)
                </td>
                <td>
                  {userScore.lowestSubject.subject} (
                  {userScore.lowestSubject.averagePercentage.toFixed(2)}%)
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      {/* Display individual subject scores below the table */}
      <div className="my-4">
        <h5 className="text-primary">Scores by Subject</h5>
        {subjectScores.length > 0 ? (
          <ul>
            {subjectScores.map((score, index) => (
              <li key={index}>
                <strong>{score.subject} : </strong> {score.totalAnswered} questions answered,{" "}
                {score.averagePercentage.toFixed(2)}%
              </li>
            ))}
          </ul>
        ) : (
          <p>No subject scores available.</p>
        )}
      </div>
    </div>
  );
};

export default UserQuizScores;
