import React, { useState } from "react";
import { auth } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";

function TeacherPayment({
  name,
  price,
  description,
  sub_id,
  displayName,
  uid,
}) {
  const [formData, setFormData] = useState({
    name_first: "",
    email_address: "",
    cell_number: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <div>
      <form action="https://www.payfast.co.za/eng/process" method="post">
        <input type="hidden" name="merchant_id" value="22710204" />
        <input type="hidden" name="merchant_key" value="oxkvlp5fafujj" />
        <input type="hidden" name="amount" value="5" />
        <input type="hidden" name="item_name" value={name} />
        <input
          type="hidden"
          name="return_url"
          value="https://austin-dlsx.onrender.com/"
        />
        <input
          type="hidden"
          name="cancel_url"
          value="https://austin-dlsx.onrender.com/"
        />
        <input
          type="hidden"
          name="notify_url"
          value="https://austin-dlsx.onrender.com/"
        />
        <input type="hidden" name="m_payment_id" value={sub_id} />
        <input type="hidden" name="item_description" value={description} />
        <input type="hidden" name="custom_int1" value="2" />
        <input
          type="hidden"
          name="custom_str1"
          value={`subject id: ${sub_id} , teacher id: ${uid}`}
        />

        <input
          name="name_first"
          value={formData.name_first}
          onChange={handleChange}
          placeholder="First Name"
          className="payment-input my-2"
          required
        />

        <input
          name="email_address"
          value={formData.email_address}
          onChange={handleChange}
          placeholder="Email Address"
          className="payment-input my-2"
          required
        />

        <input
          name="cell_number"
          value={formData.cell_number}
          onChange={handleChange}
          placeholder="Cell Number"
          className="payment-input my-2"
          required
        />

        <input type="hidden" name="email_confirmation" value="1" />
        <input
          type="hidden"
          name="confirmation_address"
          value={formData.email_address}
        />
        <input type="hidden" name="payment_method" value="cc" />

        <input
          className="btn btn-success"
          value="Complete Upload"
          type="submit"
        />
      </form>
    </div>
  );
}

export default TeacherPayment;
